import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { LotSource, LotTypeEnum } from 'src/app/modules/graphql/service/graphql-auto-main-service';
import Swiper, { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

import { MediaItem } from '../../types/media-item.type';
import { MediaOrderChanged } from '../../types/media-order-changed.type';

@Component({
  selector: 'app-lot-carousel-main-slider',
  templateUrl: './lot-carousel-main-slider.component.html',
  styleUrls: ['./lot-carousel-main-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCarouselMainSliderComponent implements OnChanges {
  @Input() lotId: string;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() auctionName: string;
  @Input() activeSlideIndex = 0;
  @Input() media: MediaItem[] = [];
  @Input() isUsingSavedMedia = true;
  @Output() mediaSaved = new EventEmitter<void>();
  @Output() activeSlideIndexChange = new EventEmitter<number>();
  @Output() mediaOrderChanged = new EventEmitter<MediaItem[]>();

  @ViewChild('swiper', { static: false }) swiper!: SwiperComponent;

  configSwiper: SwiperOptions = {
    loop: true,
    keyboard: true,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: true,
  };

  setActiveSlide(index: number): void {
    this.activeSlideIndex = index;
    this.swiperRef?.slideTo(index);
    this.activeSlideIndexChange.emit(index);
  }

  orderChanged(change: MediaOrderChanged): void {
    this.setActiveSlide(change.activeSlideIndex);
    this.mediaOrderChanged.emit(change.media);
  }

  mediaItemLoadingError(mediaItem: MediaItem): void {
    this.media = this.media.filter((mi) => mi !== mediaItem);
    this.mediaOrderChanged.emit(this.media);
  }

  private get swiperRef(): Swiper | null {
    return this.swiper?.swiperRef || null;
  }

  ngOnChanges(): void {
    if (this.swiperRef && this.swiperRef?.activeIndex !== this.activeSlideIndex) {
      this.swiperRef.slideTo(this.activeSlideIndex);
    }
  }
}
