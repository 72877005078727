import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import {
  CurrentLotMarksListsGQL,
  CurrentLotMarksListsQuery,
  CurrentLotMarksListsQueryVariables,
  LotSource,
  LotTypeEnum,
  UserLotMarkEnum,
  UserLotMarkCreateGQL,
  UserLotMarksListCreateGQL,
} from '../../graphql/service/graphql-auto-main-service';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AutoLotView } from '../../../const';
import { LotAddSharedListModalComponent } from './components/lot-add-shared-list-modal/lot-add-shared-list-modal.component';
import { MutationHandlingServiceV2, QueryHandlingService, WatchQueryRef } from '@akebono/core';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-lot-add-shared-list-modal-button',
  templateUrl: './lot-add-shared-list-modal-button.component.html',
  styleUrls: ['./lot-add-shared-list-modal-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotAddSharedListModalButtonComponent implements OnInit {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() fullSize = false;
  @Input() isUpdate = false;
  @Output() actionCompleted = new EventEmitter<void>();

  queryRef?: WatchQueryRef<CurrentLotMarksListsQuery, CurrentLotMarksListsQueryVariables>;
  markCreating$ = of(false);
  listCreating$ = of(false);
  currentLotMarksLists$: Observable<string[]>;

  constructor(
    private qhs: QueryHandlingService,
    private mhs: MutationHandlingServiceV2,
    private readonly translate: TranslateService,
    private readonly modalService: NzModalService,
    private currentLotMarksListsGQL: CurrentLotMarksListsGQL,
    private userLotMarksListCreateGQL: UserLotMarksListCreateGQL,
    private userLotMarkCreateGQL: UserLotMarkCreateGQL,
  ) {}

  ngOnInit(): void {
    this.queryRef = this.qhs.watch(this.currentLotMarksListsGQL, {});

    this.currentLotMarksLists$ = this.queryRef.data.pipe(
      map((data) => data?.currentUser?.lotMarksLists?.map((list) => list.title)),
    );
  }

  userLotMarkCreate(userLotMarksListId: string): void {
    this.markCreating$ = this.mhs.mutate(
      this.userLotMarkCreateGQL,
      {
        input: {
          lotId: this.lot.id,
          lotType: this.lotType,
          lotSource: this.lotSource,
          mark: UserLotMarkEnum.Shared,
          userLotMarksListId,
        },
      },
      {
        refetch: true,
        successTranslationKey: 'MUTATION_SUCCEEDED',
        failureTranslationKey: 'MUTATION_FAILED',
      },
    ).loading;
  }

  userLotMarksListCreate(): void {
    const collectionTitle = this.translate.instant('SHARED.ADD_NEW_LISTS');
    this.currentLotMarksLists$
      .pipe(
        take(1),
        map((titles) =>
          titles
            .filter((title) => title.startsWith(collectionTitle))
            .map((title) => {
              const match = title.match(/\d+$/);
              return match ? parseInt(match[0], 10) : 0;
            }),
        ),
        map((indexes) => {
          const newIndex = indexes.length ? Math.max(...indexes) + 1 : 1;
          return indexes.length ? `${collectionTitle} ${newIndex}` : collectionTitle;
        }),
        switchMap(
          (newTitle) =>
            (this.listCreating$ = this.mhs.mutate(
              this.userLotMarksListCreateGQL,
              {
                input: {
                  title: newTitle,
                  publishedAt: new Date().toISOString(),
                },
              },
              {
                refetch: true,
                successTranslationKey: 'MUTATION_SUCCEEDED',
                failureTranslationKey: 'MUTATION_FAILED',
              },
            ).loading),
        ),
      )
      .subscribe();
  }

  // openLotAddListButtonComponent(): void {
  //   this.modalService.create({
  //     nzTitle: this.translate.instant('SHARED.SELECT_LIST'),
  //     nzContent: LotAddSharedListModalComponent,
  //     nzWidth: this.fullSize ? '80%' : '40%',
  //     nzComponentParams: {
  //       lot: this.lot,
  //       lotType: this.lotType,
  //       lotSource: this.lotSource,
  //       userLotMarks: this.userLotMarks,
  //     },
  //     nzFooter: null,
  //   });
  // }
}
