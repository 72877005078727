import { MutationHandlingServiceV2 } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  UserLotMarksList,
  UserLotMarksListUpdateGQL,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-collection-rename-modal',
  templateUrl: './collection-rename-modal.component.html',
  styleUrls: ['./collection-rename-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionRenameModalComponent implements OnInit {
  @Input() mark: UserLotMarksList;

  form: FormGroup;
  loading$: Observable<boolean>;

  constructor(
    @Optional() private modal: NzModalRef,
    private formBuilder: FormBuilder,
    private mhs: MutationHandlingServiceV2,
    private userLotMarksListUpdateGQL: UserLotMarksListUpdateGQL,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      collectionTitle: [this.mark?.title || ''],
    });
  }

  closeModal(): void {
    this.modal.destroy();
  }

  collectedNamelUpdate(): void {
    this.loading$ = this.mhs.mutate(
      this.userLotMarksListUpdateGQL,
      {
        input: {
          id: this.mark?.id,
          title: this.form.value.collectionTitle,
        },
      },
      {
        refetch: true,
      },
    ).loading;

    this.loading$ = this.loading$.pipe(finalize(() => this.closeModal()));
  }
}
