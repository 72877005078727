import { WeekDay } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription, timer } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isHybridOrElectric } from 'src/app/hybrids';
import { LimitsService } from 'src/app/services/limits.service';

import { AutoLotView } from '../../../const';
import {
  LotTranslation,
  LotTypeEnum,
  UserLotQuery,
} from '../../graphql/service/graphql-auto-main-service';
import { LotSource } from '../../graphql/service/graphql-cars-default-service';
import { LotTranslateRequestCreateModalComponent } from './components/lot-translate-request-create-modal/lot-translate-request-create-modal.component';

@Component({
  selector: 'app-lot-create-translation-request-button',
  templateUrl: './lot-create-translation-request-button.component.html',
  styleUrls: ['./lot-create-translation-request-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCreateTranslationRequestButtonComponent implements OnInit, OnDestroy {
  @Input() currentUser: UserLotQuery['currentUser'];
  @Input() lot: AutoLotView = null;
  @Input() lotSource: LotSource;
  @Input() lotType: LotTypeEnum;
  @Input() translations: LotTranslation[] = [];
  @Input() fullSize = false;
  @Output() clickIfTranslationsExists = new EventEmitter<void>();
  @Output() translationRequestCreated = new EventEmitter<void>();

  @HostBinding('style.display')
  private display: 'none' | 'inline-block' = 'none';
  private subscription = Subscription.EMPTY;

  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private modalService: NzModalService,
    private limitService: LimitsService,
  ) {}

  ngOnInit(): void {
    this.display = 'inline-block';

    if (this.isManager) {
      this.display = 'inline-block';
    } else if (this.isRUSanctioned || this.lot.status === 'won') {
      this.display = 'none';
    } else {
      this.subscription = timer(0, 1000)
        .pipe(
          map(() => {
            const oneHourTimeLimitReached = this.limitService.checkOneHourBeforeAuctionReached(
              this.lot?.time,
            );

            return oneHourTimeLimitReached || this.saturdayTimeLimitReached;
          }),
          distinctUntilChanged(),
        )
        .subscribe((isReached) => {
          this.display = isReached ? 'none' : 'inline-block';
          this.cdr.markForCheck();
        });
    }
  }

  get isManager() {
    return this.currentUser.isAuctioneer || this.currentUser.isTranslator;
  }

  get isRUSanctioned() {
    return (
      this.lotType !== LotTypeEnum.Moto &&
      this.currentUser?.countryIso?.toLowerCase() === 'ru' &&
      (Number(this.lot.engineVolume) >= 1900 || isHybridOrElectric(this.lot))
    );
  }

  openCreateUserRequestModal(): void {
    const hasRussianAudio = this.translations.some((t) => t.language === 'ru' && t.file);
    const hasRussianText = this.translations.some((t) => t.language === 'ru' && t.message);
    const hasEnglishText = this.translations.some((t) => t.language === 'en' && t.message);

    if (!hasRussianAudio || !hasRussianText || !hasEnglishText) {
      this.modalService.create({
        nzTitle: this.translate.instant('USER_REQUESTS.LOT_TRANSLATE_REQUEST'),
        nzContent: LotTranslateRequestCreateModalComponent,
        nzComponentParams: {
          lotId: this.lot.id,
          lotType: this.lotType,
          lotSource: this.lotSource,
          currentUser: this.currentUser,
          translations: this.translations,
          translationRequestCreated: this.translationRequestCreated,
        },
      });
    } else {
      this.clickIfTranslationsExists.emit();
    }
  }

  get saturdayTimeLimitReached(): boolean {
    const nowJp = DateTime.now().setZone('Asia/Tokyo');
    const auctionDate = DateTime.fromISO(this.lot.date, {
      zone: 'Asia/Tokyo',
    });

    return (
      auctionDate.startOf('day').equals(nowJp.startOf('day')) &&
      nowJp.weekday === WeekDay.Saturday &&
      nowJp.hour >= 11
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
