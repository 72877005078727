<ng-container *ngIf="mediaType === 'image'">
  <img [src]="source" referrerpolicy="no-referrer" />
</ng-container>

<ng-container *ngIf="mediaType === 'video'">
  <video [controls]="false">
    <source [src]="source" type="video/mp4" />
  </video>
  <div class="video-overlay">
    <span nz-icon nzType="play-circle"></span>
  </div>
</ng-container>
