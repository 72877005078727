import { MutationHandlingServiceV2 } from '@akebono/core';
import { WeekDay } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, takeUntil } from 'rxjs/operators';

import {
  CurrentUser,
  LotSource,
  LotTranslateRequestCreateGQL,
  LotTypeEnum,
  PreferredLang,
  TranslationLanguage,
  TranslationMediaTypeEnum,
} from '../../../../graphql/service/graphql-auto-main-service';
import {
  LotTranslation,
  S3File,
} from '@akebono/core/lib/graphql/service/graphql-currency-exchange-service';

@Component({
  selector: 'app-lot-translate-request-create-modal',
  templateUrl: './lot-translate-request-create-modal.component.html',
  styleUrls: ['./lot-translate-request-create-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotTranslateRequestCreateModalComponent implements OnInit, OnDestroy {
  @Input() lotId: string;
  @Input() lotSource: LotSource;
  @Input() lotType: LotTypeEnum;
  @Input() currentUser: CurrentUser;
  @Input() translations: LotTranslation[] = [];
  @Output() translationRequestCreated = new EventEmitter<void>();

  form: FormGroup;
  creating$: Observable<boolean>;
  isSaturday = DateTime.now().weekday === WeekDay.Saturday;

  translationLanguage = Object.values(TranslationLanguage);
  translationMediaTypes = Object.values(TranslationMediaTypeEnum);
  translationsSet: Set<string> = new Set();

  private readonly destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    public modal: NzModalRef,
    private mhs: MutationHandlingServiceV2,
    private lotTranslateRequestCreateGQL: LotTranslateRequestCreateGQL,
  ) {
    this.form = this.fb.group({
      language: this.fb.control(null),
      translationMediaType: this.fb.control(null),
    });

    combineLatest([
      this.form.get('language').valueChanges.pipe(distinctUntilChanged()),
      this.form.get('translationMediaType').valueChanges.pipe(distinctUntilChanged()),
    ])
      .pipe(
        map(([lang]) => lang === PreferredLang.En),
        filter(Boolean),
        takeUntil(this.destroy$),
      )
      .subscribe(() =>
        this.form.patchValue({
          translationMediaType: TranslationMediaTypeEnum.Text,
        }),
      );
  }

  ngOnInit(): void {
    this.form.patchValue({
      language: this.currentUser.preferredLang,
      translationMediaType: this.currentUser.defaultTranslationMediaType,
    });

    this.translations.forEach((translation) => {
      if (translation.language) {
        this.translationsSet.add(this.getTranslationKey(translation));
      }
    });
  }

  destroyModal(): void {
    this.modal.destroy();
  }

  private getTranslationKey(translation: LotTranslation): string;
  private getTranslationKey(language: string, mediaType: TranslationMediaTypeEnum): string;
  private getTranslationKey(
    translationOrLanguage: string | LotTranslation,
    mediaType?: TranslationMediaTypeEnum,
  ): string {
    if (typeof translationOrLanguage === 'string') {
      return `${translationOrLanguage}_${mediaType}`;
    } else {
      const currentMediaType = translationOrLanguage.file
        ? TranslationMediaTypeEnum.Audio
        : TranslationMediaTypeEnum.Text;

      return `${translationOrLanguage.language}_${currentMediaType}`;
    }
  }

  isMediaTypeDisabled(mediaType: TranslationMediaTypeEnum): boolean {
    const language = this.form.get('language').value;
    const translationKey = this.getTranslationKey(language, mediaType);

    return (
      (language === TranslationLanguage.En && mediaType === TranslationMediaTypeEnum.Audio) ||
      this.translationsSet.has(translationKey)
    );
  }

  submit(): void {
    const value = this.form.getRawValue();
    this.creating$ = this.mhs
      .mutate(
        this.lotTranslateRequestCreateGQL,
        {
          input: {
            lotId: this.lotId,
            lotType: this.lotType,
            lotSource: this.lotSource,
            targetLanguage: value.language,
            translationMediaType: value.translationMediaType,
          },
        },
        { refetch: true },
      )
      .loading.pipe(
        finalize(() => {
          this.translationRequestCreated.emit();
          this.destroyModal();
        }),
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
