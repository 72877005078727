<app-lot-carousel-main-slider
  [lotId]="lot.id"
  [lotType]="lotType"
  [lotSource]="lotSource"
  [activeSlideIndex]="activeSlide"
  [media]="media"
  [isUsingSavedMedia]="isUsingSavedImages"
  (mediaSaved)="mediaChanged.emit()"
  (mediaOrderChanged)="media = $event"
  (activeSlideIndexChange)="activeSlideChanged($event)"
></app-lot-carousel-main-slider>

<app-lot-carousel-thumbs
  [media]="media"
  (activeSlideIndexChanged)="activeSlideChanged($event)"
></app-lot-carousel-thumbs>
