import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { MediaItem } from '../../types/media-item.type';
import { LotCarouselMainSliderVideoModalComponent } from '../lot-carousel-main-slider-video-modal/lot-carousel-main-slider-video-modal.component';

@Component({
  selector: 'app-lot-carousel-main-slider-item',
  templateUrl: './lot-carousel-main-slider-item.component.html',
  styleUrls: ['./lot-carousel-main-slider-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCarouselMainSliderItemComponent {
  @Input() mediaItem: MediaItem;
  @Output() loadingError = new EventEmitter<MediaItem>();

  loaded = false;

  get mediaType(): MediaItem['mediaType'] {
    return this.mediaItem.mediaType;
  }

  get source(): string {
    return this.mediaItem.source;
  }

  loadingFailed(): void {
    this.loadingError.emit(this.mediaItem);
  }

  constructor(private readonly modalService: NzModalService) {}

  openVideoModal(): void {
    if (this.mediaItem.mediaType !== 'video' || !this.loaded) {
      return;
    }

    this.modalService.create({
      nzContent: LotCarouselMainSliderVideoModalComponent,
      nzComponentParams: {
        source: this.mediaItem.source,
      },
      nzFooter: null,
      nzClosable: false,
      nzCentered: true,
      nzWidth: '100%',
      nzBodyStyle: {
        padding: '0',
      },
    });
  }
}
