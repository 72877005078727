import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { LotAddSharedListModalButtonComponent } from './lot-add-shared-list-modal-button.component';
import { LotAddSharedListModalComponent } from './components/lot-add-shared-list-modal/lot-add-shared-list-modal.component';
import { LotSharedAddListButtonModule } from '../lot-shared-add-list-button/lot-shared-add-list-button.module';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
@NgModule({
  declarations: [LotAddSharedListModalButtonComponent, LotAddSharedListModalComponent],
  imports: [
    CommonModule,
    NzDropDownModule,
    NzButtonModule,
    TranslateModule,
    LotSharedAddListButtonModule,
    NzSelectModule,
    NzIconModule,
    NzInputModule,
    NzDividerModule,
    NzSpinModule,
    NzPopoverModule,
  ],
  exports: [LotAddSharedListModalButtonComponent],
})
export class LotAddSharedListModalButtonModule {}
