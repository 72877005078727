<section>
  <ng-container *ngTemplateOutlet="mediaListTemplate; context: { media: regularMedia }">
  </ng-container>
</section>

<section *ngIf="inspectionMedia.length">
  <h3>{{ 'LOT_CAROUSEL.INSPECTION_FILES' | translate }}</h3>
  <ng-container *ngTemplateOutlet="mediaListTemplate; context: { media: inspectionMedia }">
  </ng-container>
</section>

<ng-template #mediaListTemplate let-media="media">
  <nz-row [nzGutter]="[5, 5]">
    <nz-col
      *ngFor="let mediaItem of media"
      [nzXXl]="3"
      [nzXl]="4"
      [nzLg]="6"
      [nzMd]="3"
      [nzSm]="4"
      [nzXs]="6"
    >
      <app-lot-carousel-thumbs-item
        [mediaItem]="mediaItem"
        [index]="mediaItem.index"
        (thumbSelected)="activeSlideIndexChanged.emit($event)"
      ></app-lot-carousel-thumbs-item>
    </nz-col>
  </nz-row>
</ng-template>
