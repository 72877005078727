<div class="content">
  <div class="wrap">
    <h3 nz-title>{{ 'SELECTIONS' | translate }}</h3>

    <ng-container *ngIf="(queryRef?.data | async)?.currentUser?.lotMarksLists as lotMarksLists">
      <nz-divider [nzText]="'SELECTIONS' | translate" nzOrientation="left"></nz-divider>
      <nz-button-group style="margin: 5px 0; margin-right: 10px">
        <button
          nz-button
          (click)="setFilterMarkListById()"
          [nzType]="!activeListMarkId ? 'primary' : 'dashed'"
        >
          {{ 'NO_LIST' | translate }}
        </button>
      </nz-button-group>
      <nz-button-group *ngFor="let mark of lotMarksLists" style="margin: 5px 0; margin-right: 10px">
        <button
          nz-button
          (click)="setFilterMarkListById(mark.id)"
          nz-popover
          [nzPopoverContent]="content"
          [nzType]="activeListMarkId === mark.id ? 'primary' : 'default'"
        >
          {{ mark.title }}
        </button>

        <ng-template #content>
          <div style="margin-bottom: 5px">
            <app-list-share-button
              [id]="mark.id"
              [shareLotLink]="getListLink(mark)"
            ></app-list-share-button>
          </div>
          <div style="margin-bottom: 5px">
            <button
              style="width: 100%"
              nz-button
              nzDanger
              *ngIf="mark.publishedAt"
              [nzLoading]="(updatingListMark2$?.[mark.id] | async)"
              (click)="updateLotMarksListUpdate(mark.id, null)"
            >
              {{ 'SHARED.CLOSE_ACCESS' | translate }}
            </button>
          </div>
          <div style="margin-bottom: 5px">
            <button
              style="width: 100%"
              nz-button
              (click)="renameCollectionModal(mark)"
            >
              {{ 'SHARED.CHANGE_SELECTION' | translate }}
              <i nz-icon nzType="edit" nzTheme="outline"></i>
            </button>
          </div>
          <div>
            <button
              style="width: 100%"
              nz-button
              nzDanger
              nzType="primary"
              (click)="userLotMarksListDelete(mark.id)"
              [nzLoading]="(deletingListMark$?.[mark.id] | async)"
              [disabled]="(deletingListMark$?.[mark.id] | async)"
            >
              {{ 'SHARED.DELETE_LIST' | translate }}
            </button>
          </div>
        </ng-template>
      </nz-button-group>

      <nz-divider></nz-divider>

      <nz-row [nzGutter]="[30, 10]">
        <nz-col>
          <nz-row>
            <nz-col>
              <input
                type="text"
                [(ngModel)]="titleNewList"
                [placeholder]="'SHARED.ADD_NEW_LIST' | translate"
                nz-input
              />
            </nz-col>
            <nz-col>
              <button
                nz-button
                nzType="primary"
                (click)="userLotMarksListCreate()"
                [nzLoading]="addingListMark$ | async"
                [disabled]="addingListMark$ | async"
              >
                <i nz-icon nzType="plus" nzTheme="outline"></i>
              </button>
            </nz-col>
          </nz-row>
        </nz-col>
        <nz-col>
          <nz-row nzAlign="middle">
            <nz-col>
              <span style="padding-right: 10px"> {{ 'SHARED.MOVE_TO' | translate }}:</span>
            </nz-col>
            <nz-col>
              <nz-select
                style="width: 250px"
                nzShowSearch
                [(ngModel)]="newUserLotMarksListId"
                [nzPlaceHolder]="'SHARED.SELECT_LIST' | translate"
                [nzLoading]="queryRef.loading | async"
                [nzDisabled]="!setOfCheckedId.size || updatingListMark$"
              >
                <nz-option
                  *ngFor="let item of lotMarksLists"
                  [nzLabel]="item?.title"
                  [nzValue]="item?.id"
                ></nz-option>
              </nz-select>
            </nz-col>
            <nz-col>
              <button
                nz-button
                nzType="primary"
                (click)="userLotMarksUpdate()"
                [nzLoading]="updatingListMark$ | async"
                [disabled]="!setOfCheckedId.size || !newUserLotMarksListId"
              >
                {{ 'SHARED.MOVE' | translate }}
              </button>
            </nz-col>
          </nz-row>
        </nz-col>
      </nz-row>

      <nz-divider [nzText]="'CHANNEL.LABEL' | translate" nzOrientation="left"></nz-divider>
      <ng-container *ngIf="(queryRef?.data | async)?.currentUser as user">
        <button nz-button nzType="primary" *ngIf="!user?.userChannel" (click)="createModal()">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          {{ 'CREATE' | translate }}
        </button>

        <nz-button-group *ngIf="user?.userChannel">
          <app-share-button
            [shareLotLink]="getChannelLink(user?.userChannel?.path)"
            nzSize="default"
          >
          </app-share-button>
          <button
            nz-button
            nzType="primary"
            style="margin: 0 10px"
            (click)="createModal(user.userChannel)"
          >
            {{ 'EDIT' | translate }}
            <i nz-icon nzType="edit" nzTheme="outline"></i>
          </button>
          <button
            nzDanger
            nz-button
            nz-popconfirm
            nzType="primary"
            [disabled]="deletingChannel$ | async"
            [nzLoading]="deletingChannel$ | async"
            [nzPopconfirmTitle]="'ARE_YOU_SURE' | translate"
            (nzOnConfirm)="userChannelDelete(user.userChannel.id)"
          >
            {{ 'DELETE' | translate }}
            <i nz-icon nzType="delete" nzTheme="outline"></i>
          </button>
        </nz-button-group>
      </ng-container>

      <nz-divider></nz-divider>
    </ng-container>
  </div>

  <nz-table
    #table
    class="table"
    nzSize="small"
    nzOuterBordered
    nzShowSizeChanger
    [nzBordered]="true"
    [nzPageIndex]="page"
    [nzPageSize]="pageSize"
    [nzFrontPagination]="false"
    nzPaginationPosition="both"
    [nzLoading]="queryRef?.loading | async"
    (nzPageIndexChange)="changePage($event)"
    (nzPageSizeChange)="changePageSize($event)"
    [nzTotal]="(queryRef?.data | async)?.currentUser?.lotMarks?.total"
    [nzData]="(queryRef?.data | async)?.currentUser?.lotMarks?.nodes"
    [nzTitle]="
      ('TOTAL' | translate) + ': ' + ((queryRef?.data | async)?.currentUser?.lotMarks?.total || 0)
    "
  >
    <thead>
      <tr>
        <th
          rowspan="2"
          nzWidth="30px"
          nzShowCheckbox
          [(nzChecked)]="checkedAllMarks"
          (nzCheckedChange)="updateCheckedAllObjects($event, table.data)"
        ></th>
        <th rowspan="2" nzAlign="center" style="font-weight: bold">{{ 'PHOTO' | translate }}</th>
        <th rowspan="2" nzAlign="center" style="font-weight: bold">{{ 'LOT' | translate }}</th>
        <th rowspan="1" nzAlign="center" style="font-weight: bold">
          {{ 'LOT_INFO.AUCTION' | translate }}
        </th>
        <th rowspan="1" nzAlign="center" style="font-weight: bold">
          {{ 'LOT_INFO.YEAR' | translate }}
        </th>
        <th
          rowspan="1"
          nzAlign="center"
          style="font-weight: bold"
          [ngClass]="'hide-column-small hide-column-medium'"
        >
          {{ 'LOT_INFO.ENGINE_CC' | translate }}
        </th>
        <th rowspan="1" nzAlign="center" style="font-weight: bold">
          {{ 'LOT_INFO.MILEAGE' | translate }}
        </th>
        <th rowspan="1" nzAlign="center" style="font-weight: bold">
          {{ 'LOT_INFO.START_PRICE' | translate }}
        </th>
        <th nzWidth="1px" rowspan="2"></th>
      </tr>
      <tr>
        <th nzAlign="center" style="font-weight: bold">{{ 'DATE' | translate }}</th>
        <th nzAlign="center" style="font-weight: bold">{{ 'FRAME_MODEL' | translate }}</th>
        <th nzAlign="center" style="font-weight: bold">{{ 'LOT_INFO.COLOR' | translate }}</th>
        <th nzAlign="center" style="font-weight: bold">{{ 'LOT_INFO.SCORE' | translate }}</th>
        <th nzAlign="center" style="font-weight: bold">{{ 'LOT_INFO.SOLD_FOR' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let mark of table.data">
        <td
          nzShowCheckbox
          [nzChecked]="setOfCheckedId.has(mark.id)"
          (nzCheckedChange)="updateCheckedObject($event, mark.id)"
        ></td>
        <td nzAlign="center">
          <app-lot-mini-gallery [lot]="mark.lot"></app-lot-mini-gallery>
        </td>
        <td nzAlign="center">
          <div class="lot-buttons">
            <a
              nzSize="small"
              target="_blank"
              class="lot-button"
              nz-button
              [href]="mark.lot.link"
              nzType="primary"
            >
              {{ mark.lot.code }}
            </a>
          </div>
          <div>
            <app-share-button [shareLotLink]="getSharedLink(mark)" nzSize="small">
            </app-share-button>
          </div>
        </td>
        <td nzAlign="center">
          {{ mark.lot.auctionName }} <br />
          {{ mark.lot.auctionDatetime | date: 'yyyy-MM-dd H:mm:ss zz':'+0900' }}
        </td>
        <td nzAlign="left" [ngClass]="'hide-column-small hide-column-medium'">
          <span style="color: #6db3eb">{{ mark.lot.companyEn }} {{ mark.lot.modelEn }}</span>
          <br />
          <span nz-text nzType="danger">{{ mark.lot.year }}</span> {{ mark.lot.modelTypeEn }}
          <br />
          <span nz-text nzType="secondary">{{ mark.lot.modelGradeEn }}</span>
        </td>
        <td nzAlign="left">
          <span nz-text nzType="danger">{{ mark.lot.transmissionEn }}</span>
          {{ mark.lot.engineVolumeNum }} cc<br />
          <span nz-text nzType="secondary">{{ mark.lot.colorEn }}</span>
        </td>
        <td nzAlign="center">
          {{ mark.lot.mileageNum }}000km
          <span nz-text nzType="warning">{{ mark.lot.rate }}</span>
        </td>
        <td nzAlign="center">
          {{ mark.lot.startPriceNum | currency: 'JPY':'symbol-narrow' }}
          <br />
          {{ mark.lot.endPriceNum | currency: 'JPY':'symbol-narrow' }}
        </td>
        <td nzAlign="center">
          <button
            nz-button
            nzDanger
            nz-popconfirm
            nzType="primary"
            [nzLoading]="deleting[mark.id] | async"
            (nzOnConfirm)="remove(mark)"
            [nzPopconfirmTitle]="'ARE_YOU_SURE_DELETE' | translate"
          >
            <i nz-icon nzType="delete" nzTheme="outline"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>
