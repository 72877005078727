import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { AutoLotView } from 'src/app/const';
import { Limits } from 'src/app/services/limits.service';

import {
  Bid,
  LotSource,
  LotTranslation,
  LotTypeEnum,
  UserLotMark,
  UserLotQuery,
} from '../../graphql/service/graphql-auto-main-service';
import { LotActionEnum } from './lot-action.enum';

@Component({
  selector: 'app-lot-actions',
  templateUrl: './lot-actions.component.html',
  styleUrls: ['./lot-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotActionsComponent implements OnChanges {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() isAraiKenki = false;
  @Input() hasTranslations = false;
  @Input() translations: LotTranslation[] = [];
  @Input() limits: Limits;
  @Input() bids: Bid[];
  @Input() isLotSanctioned = false;
  @Input() savedLot: UserLotQuery['lot'];
  @Input() userLotMarks: UserLotMark[];
  @Input() currentUser: UserLotQuery['currentUser'];
  @Input() currentUserLimitsOverrideAvailable = false;
  @Input() currentUserLastBid?: UserLotQuery['lot']['currentUserLastBid'];

  @Output() actionCompleted = new EventEmitter<void>();
  @Output() scrollToSales = new EventEmitter<void>();
  @Output() scrollToTranslations = new EventEmitter<void>();

  actionsDrawerOpened = false;

  private customActionAvailableCondition: Partial<Record<LotActionEnum, boolean>> = {};
  private readonly unavailableActionsByLotType: Record<LotActionEnum & 'kenki', LotActionEnum[]> = {
    kenki: [LotActionEnum.Bids, LotActionEnum.Reset, LotActionEnum.CreateTranslationRequest],
    [LotTypeEnum.Oneprice]: [LotActionEnum.Bids, LotActionEnum.Reset],
    [LotTypeEnum.Moto]: [LotActionEnum.Notations],
  };

  LotActionEnum = LotActionEnum;

  isActionAvailable(action: LotActionEnum): boolean {
    const unavailableActions = <LotActionEnum[]>(
      (this.unavailableActionsByLotType[this.isAraiKenki ? 'kenki' : this.lotType] || [])
    );

    if (unavailableActions.includes(action)) {
      return false;
    }

    return this.customActionAvailableCondition[action] ?? true;
  }

  ngOnChanges(): void {
    this.customActionAvailableCondition = {
      [LotActionEnum.PlaceBid]: !!this.lot,
      [LotActionEnum.Bids]: this.currentUser.canSeeUsersBids,
      [LotActionEnum.Reset]: this.lot.isLotDataChanged,
      [LotActionEnum.PurchaseReport]: this.currentUser.isAuctioneer,
    };
  }
}
