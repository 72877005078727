<ng-container *ngIf="mediaType === 'image'">
  <img
    nz-image
    [nzSrc]="source"
    referrerpolicy="no-referrer"
    (load)="loaded = true"
    (error)="loadingFailed()"
  />
</ng-container>

<ng-container *ngIf="mediaType === 'video'">
  <video [controls]="false" (loadeddata)="loaded = true" (error)="loadingFailed()">
    <source [src]="source" type="video/mp4" />
  </video>
  <div class="video-overlay" (click)="openVideoModal()">
    <span nz-icon nzType="play-circle"></span>
  </div>
</ng-container>
