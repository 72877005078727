import { ru } from './ru';

export const en: typeof ru = {
  EDIT: 'Edit',
  UPDATE: 'Update',
  LANGUAGES: 'Languages',
  RU: 'Russian',
  EN: 'English',
  ANY: 'ANY',
  ANY_M: 'ANY',
  ERROR: 'Error',
  WARNING: 'Warning',
  ATTENTION: 'Attention',
  SHOW: 'Show',
  SHOW_EXTENDED_PERIOD: 'Show for extended period',
  DELETE: 'Delete',
  SELECTIONS: 'Selections',
  BOUNDARY_LOTS: 'Boundary',
  TOTAL_BOUNDARY: 'Total boundaries: {{count}}',
  ON_DATE_BOUNDARY: 'Boundaries on {{date}}: {{count}}',
  ARE_YOU_SURE: 'Are you sure?',
  ARE_YOU_SURE_DELETE: 'Are you sure you want to delete?',
  INFORMATION: 'Reference information',
  TRANSLATION: 'Translation',
  AUTO: 'Auto',
  MOTO: 'Moto',
  SELECT_AUCTION: 'Select auction',
  COMPANY_MODEL: 'Maker, Model',
  SELECT_COMPANY: 'Please select company',
  COMPANY: 'Company',
  SELECT_MODEL: 'Please select model',
  MODEL: 'Model',
  ENGINE_VOLUME: 'Engine Volume',
  SEE_ALL: 'See all',
  PLEASE_SELECT: 'Please select',
  SEARCH: 'Filter',
  CLEAR: 'Clear',
  PHOTO: 'Photo',
  LOT: 'Lot',
  GREEN_CORNER: 'Green corner',
  NOTIFY: 'Notify about the results of the auction',
  TRANSLATE: 'Translation requested',
  NO_LIST: 'No list',
  LOT_MANUALLY: 'Lot manually',
  FILTER_DATA_OUTDATED: 'Filter data may be outdated. Reset filter to refresh its data.',
  FILTERS: 'FILTERS',
  DATE: 'Date',
  EQUIPMENT: 'Equipment',
  SELECT_LOT_TYPE: 'Select lot type',
  RATE_MUST_BE_HIGHER: 'The rate must be greater than or equal to the starting price',
  WRONG_YEAR: 'Wrong year',
  WRONG_BID_CONDITION: 'Wrong bid condition',
  TOTAL: 'Total',
  SIGN_OUT: 'Sign out',
  AUCTION_NOT_STARTED: 'Auction has not started yet',
  LOT_NOT_FOUND: 'Lot not found',
  ONE_MORE_CHANCE: 'One more chance',
  BID_PLACED: 'Bid placed!',
  BID_MAY_NOT_BID_PROCESSED: 'After 18:00 your bid may not be processed',
  BID_DELETE_PROHIBITED_1700: 'After 17:00 on auction day bid delete request will not be available',
  BID_DELETE_PROHIBITED_1100: 'After 11:00 on Saturday bid delete request will not be available',
  SOURCE: 'Source',
  TRANSLATE_LOT: 'Translate lot',
  PREVIOUS_SALES: 'Sales statistics',
  GUIDANCE_REQUEST_CREATED:
    'A request for information on access to viewing lots and bids has been created',
  STATISTICS: 'Statistics',
  MUTATION_FAILED: 'Failed',
  MUTATION_SUCCEEDED: 'Success',
  ENABLE_NEW_LOTS_NOTIFICATION: 'Enable notifications about new lots',
  DISABLE_NEW_LOTS_NOTIFICATION: 'Disable notifications about new lots',
  AUCTION_REMOTENESS: {
    LOW: 'Auction remoteness - low',
    MEDIUM: 'Auction remoteness - middle',
    HIGH: 'Auction remoteness - high',
  },
  FRAME_MODEL: 'Frame model',
  STATUS: 'Status',
  ARTICLES: 'Articles',
  TRANSMISSION: 'Transmission',
  HOME: 'Home',
  SEARCH_BY_BID: 'Search by bid number',
  INPUT_BID_NUMBER: 'Input bid number',
  BID_NUMBER: 'Bid number',
  IN_CURRENCY: 'In select currency: {{amount}}',
  AMOUNT: 'Amount',
  GROUP: 'Group',
  LOT_DATA: 'Lot info',
  BIDS_DATE: 'Bid time',
  MY_BIDS: 'My bids',
  BID_STATUS: 'Status',
  CONDITION: 'Export type',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',
  Sun: 'Sun',
  Mon: 'Mon',
  Tue: 'Tue',
  FROM: 'From',
  TO: 'To',
  SELECT_GROUP: 'Select',
  FRAME_NUMBER: 'Frame number',
  DATE_MANUFACURE: 'Date of manufacture',
  CLOSE: 'Close',
  CHECK: 'Check',
  CANCEL: 'Cancel',
  CREATE: 'Create',
  SAVE: 'Save',
  SAVED: 'Saved',
  CHECK_DATE_OF_MANUFACTURE: 'Check date of manufacture',
  DATE_MANUFACURE_NOT_FOUNDED: 'Date of manufacture not found by this number',
  UNDERSTANDING_THE_JAPANESE_AUCTION_SHEET: 'Understanding the Japanese Auction Sheet',
  SMALL_SCRATCH: 'Small Scratch',
  SCRATCH: 'Scratch',
  BIG_SCRATCH: 'Big Scratch',
  FEW_DIMPLES: 'Few Dimples',
  SEVERAL_DIMPLES: 'Several Dimples',
  MANY_DIMPLES: 'Many Dimples',
  SMALL_DENT: 'Small Dent',
  DENT: 'Dent',
  BIG_DENT: 'Big Dent',
  REPAIR_MARK_WAVE_HARDLY_DETECTABLE: 'Repair Mark/Wave (hardly detectable)',
  REPAIR_MARK_WAVE: 'Repair Mark/Wave',
  OBVIOUS_REPAIR_MARK_WAVE_NEEDS_TO_BE_REPAINTED:
    'Obvious Repair Mark/Wave (needs to be repainted)',
  RUST: 'Rust',
  HEAVY_RUST: 'Heavy Rust',
  CORROSION: 'Corrosion',
  HEAVY_CORROSION: 'Heavy Corrosion',
  PAINT_MARKED: 'Paint marked',
  PAINT_FADED_X_NEED_TO_BE_REPLACED: 'Paint faded X Need to be replaced',
  REPLACED: 'Replaced',
  SMALL_DENT_WITH_SCRATCH_SIZE_LIKE_A_THUMB: 'Small dent with scratch (size like a thumb)',
  DENT_WITH_SCRATCH_SIZE_LIKE_FLAT_OF_THE_HAND: 'Dent with scratch (size like flat of the hand)',
  BIG_DENT_WITH_SCRATCH_SIZE_LIKE_ELBOW: 'Big Dent with scratch (size like elbow)',
  SMALL_HOLE_OR_CRACK: 'Small Hole or Crack',
  HOLE_OR_CRACK: 'Hole or Crack',
  BIG_HOLE_OR_CRACK: 'Big Hole or Crack',
  SMALL_CRACK_ON_WINDSHIELD_APPROXIMATELY_1CM: 'Small Crack on Windshield (approximately 1cm)',
  REPAIRED_CRACK_ON_WINDSHIELD: 'Repaired Crack on Windshield',
  REPAIRED_CRACK_ON_WINDSHIELD_NEEDS_TO_BE_REPLACED:
    'Repaired Crack on Windshield (needs to be replaced)',
  CRACK_ON_WINDSHIELD_NEEDS_TO_BE_REPLACED: 'Crack on Windshield (needs to be replaced)',
  STONE_CHIP_IN_GLASS: 'Stone chip in glass',
  DATE_OF_MANUFACTURE_HINT:
    'Attention! The manufacturing date is provided by a third-party. We do not guarantee that it is correct.',
  DATE_OF_MANUFACTURE_WARNING:
    'For intentionally entering an incorrect VIN number, you will be deprived of the right to check the production date.',
  EXTRA_DESCRIPTION: 'Extra Description',
  INITIAL: 'Pending',
  CONFIRMED: 'Confirmed',
  CANCELED: 'Canceled',
  WON: 'Won',
  TRANSLATED: 'Translated',
  ALL: 'All',
  LEFT_DRIVE: 'Left',
  RIGHT_DRIVE: 'Right',
  PRODUCTION_DATE: 'Production date (checked only)',
  CREATE_PRODUCTION_DATE_CHECK_REQUEST: 'Request production date',
  CREATED_AT: 'Created At',
  TODAY: 'Today',
  ADD_PHOTO: 'Add photo',
  GET_PHOTO: 'Get photo',
  GET_PHOTO_REQUEST_HINT: 'This can take up to 2-10 minutes, depending on current load',
  PLEASE_SET_PASSWORD_0: 'Use email or username or phone, linked to account on',
  REGISTRATION: 'Sign up on',
  FOR_AUTH_USE_AKEOBONO_WORLD_ACCOUNT: 'For authorization, use your akebono.world account',
  PLEASE_SET_PASSWORD_1: 'Reset the password',
  PLEASE_SET_PASSWORD_2: 'if you are signing in for the first time.',
  VISITED: 'Visited',
  FORBIDDEN_TITLE: 'Insufficient permissions to view lots',
  FORBIDDEN_MESSAGE: 'Sorry, you have not permission. Please contact with your auction manager.',
  SEND_REQUEST_TO_ACCESS: 'Send request to access',
  SEND: 'Send',
  LAST_NAME: 'Last name',
  INPUT_ERROR_LAST_NAME: 'Last name is required',
  INPUT_LAST_NAME: 'Input your last name',
  FIRST_NAME: 'First name',
  INPUT_ERROR_FIRST_NAME: 'First name is required',
  INPUT_FIRST_NAME: 'Input your first name',
  MIDDLE_NAME: 'Middle name',
  INPUT_ERROR_MIDDLE_NAME: 'Middle name is required',
  INPUT_MIDDLE_NAME: 'Input your middle name',
  SEND_REQUEST_TO_ACCESS_SUCCESS: 'Request send successful',
  GUIDANCE_REQUEST_ALREADY_EXISTS:
    'A request for access to viewing lots has been sent. Expect approval.',
  REQUEST_TYPE_NOT_SUPPORTED: 'Request type not supported',
  Success: 'Success',
  'Bid placed!': 'Bid placed!',
  'Please fill all required fields': 'Please fill all required fields',
  'Photo request sent!': 'Photo request sent!',
  'Sign in': 'Sign in',
  'Send new password': 'Send new password',
  Auto: 'Auto',
  Manual: 'Manual lots',
  Lot: 'Lot',
  'Moto lots': 'Moto',
  'Moto lot': 'Lot',
  NOTE: 'Note',
  SELLING_STATISTICS: 'Previous auctions',
  SELECT: 'Select',
  OLD_INTERFACE: 'Previous version',
  ONEPRICE: 'OnePrice',
  FREE_SERVICE: 'Free service',
  MODEL_GRADE: 'Model grade',
  DAYS_TO_SHIP: 'Average time from purchase to shipment to Russia',
  AVERAGE_TIME_FROM_BUY_TO_SHIP: 'Average time from {{auctionName}} from buy to ship',
  DAYS: 'days',
  Автомашина: 'Car',
  'Мото техника': 'Motorcycle',
  SCORE: {
    TITLE: 'Bid score',
    RATE_0: 'No chance',
    RATE_5: 'Very low chance of buying',
    RATE_10: 'Very low chance of buying',
    RATE_15: 'Very low chance of buying',
    RATE_20: 'Very low chance of buying',
    RATE_25: 'Very low chance of buying',
    RATE_30: 'Low chance of buying',
    RATE_35: 'Low chance of buying',
    RATE_40: 'Low chance of buying',
    RATE_45: 'Medium chance of buying',
    RATE_50: 'Medium chance of buying',
    RATE_55: 'Medium chance of buying',
    RATE_60: 'Medium chance of buying',
    RATE_65: 'High chance of buying',
    RATE_70: 'High chance of buying',
    RATE_75: 'High chance of buying',
    RATE_80: 'High chance of buying',
    RATE_85: 'High chance of buying',
    RATE_90: 'Very high chance of buying',
    RATE_95: 'Very high chance of buying',
    RATE_100: 'Very high chance of buying',
  },
  DRIVER_CONFIG: {
    PREV_BTN: 'Previous',
    NEXT_BTN: 'Next',
    DONE_BTN: 'Done',
  },
  AUTO_LOT: {
    GUIDE: {
      TITLE_BLOCK: {
        TITLE: 'Lot title',
        DESCR: 'Basic lot data. Manufacturer, model, mileage, the auction and its date',
      },
      PHOTO_GALLERY: {
        TITLE: 'Photo gallery',
        DESCR:
          'Lot photos. At the bottom-left corner there is an auction lists guide. If there is no photo, please use the “Request photos“ button or ask your manager',
      },
      ADDITIONAL_TAGS: {
        TITLE: 'Additional lot tags',
        DESCR: 'Additional lot marks that can warn you about any important things about the lot',
      },
      ACTION_BTNS: {
        FIRST: {
          TITLE: 'Action buttons',
          DESCR:
            'You can open the bidding window, copy the link to current lot, request photos or translation and etc. Also, here you can access sales statistics for similar models and instructions for reading some of the symbols on auction sheets',
        },
        SECOND: {
          TITLE: 'Action buttons',
          DESCR:
            'Lot translation (if requested) will be available after our manager will complete the translation',
        },
      },
      SELECTION: {
        TITLE: 'Selections',
        DESCR:
          'Add your favorite lots to selections list so you will be able to view it later with no need to spent a lot of time to find it again. All your selections will be available on the Selections page at the top of the website',
      },
      DATE_OF_MANUFACTURE: {
        FIRST: {
          TITLE: 'Production date',
          DESCR:
            'Input the missing part of the chassis number from the auction sheet and request the exact production date of the car. The date will be available immediately if it is available in our database, otherwise you can send a request to clarify the production date to our manager',
        },
        SECOND: {
          TITLE: 'Production date',
          DESCR: 'You can find out more about production date requests in our',
          LINK: 'F.A.Q. section',
        },
      },
      LOT_DATA: {
        TITLE: 'Vehicle data',
        DESCR: 'Name, year, color mileage and etc.',
      },
      AUCTION_DATA: {
        TITLE: 'Auction data',
        DESCR:
          'Name, lot number and auction date. Also you can access the auction reviews and leave your own comment',
      },
      BIDS: {
        FIRST: {
          TITLE: 'Bids',
          DESCR:
            "Here are the main finance indicators. Start price, your bid size, the presence of other participants' bids, if any. In case of negotiations are open for the lot, you will need to confirm your participation in the negotiations. A confirmation button will be available in the same block",
        },
        SECOND: {
          TITLE: 'Bids',
          DESCR:
            'You will need to confirm participation in the auction if you placed a bid with a translation request, after receiveng a translation',
        },
      },
      SELL_STATS: {
        TITLE: 'Sales statistics',
        DESCR:
          'Here you can access the sales stats for the similar vehicle models based on our data',
      },
      ADDITIONAL_DESCR: {
        TITLE: 'Additional notes',
        DESCR: 'Here you can find additional data on the lot received from the auction',
      },
      CALC: {
        TITLE: 'Costs calculator',
        DESCR: 'The calculator will help you predict estimated costs',
      },
    },
  },
  LOT_BIDDING_MODAL: {
    HIGHT_IMPORT_DUTY:
      'High recycling fee upon import into Russia. Consult with a customs broker before purchasing.',
    MAX_BID_AMOUNT_LIMIT: 'Your maximum possible bid: {{amount}}¥',
    BID_MUST_BE_ROUND: 'Bid must be placed in steps of 5 000',
    DAILY_LIMIT_REACHED: 'The bidding limit for {{date}} has been reached',
    LOT_LIMIT_REACHED: 'The limit of bids on this lot has been reached',
    TENDER_AUCTION_TIME_LIMIT_REACHED: 'Tender auctions accept bids only up to 13:30',
    FILL_ALL_FIELDS: 'Please, fill all required fields!',
    FAIL_CREATE_GROUP: 'Failed to create a group',
    SUCCESS_CREATE_GROUP: 'The group has been created',
    FAIL_CREATE_BID: 'Failed to place a bid',
    SUCCESS_CREATE_BID: 'The bid is placed!',
    PLACE_YOUR_BID: 'Place bid',
    AMOUND_SHOULD_HINT: 'Amount should be {{amount}} or more',
    AMOUND_SHOULD_VALIDATION: 'Minimal bid step {{amount}}',
    YOUR_BID: 'Your bid',
    NO_GROUP: 'No Group',
    NEW_GROUP: 'New Group',
    QUANTITY: 'Quantity',
    TENDER_AUCTION_NO_GROUP: 'Tender auction bids cannot be grouped',
    SELECT_CONDITIONS: 'Select condition',
    YOUR_COMMENT: 'Your comment',
    TRANSLATE_REQUEST: 'Request auction list translation',
    NOTIFY_REQUEST: 'Notify me on auction result',
    NOTIFY_REQUEST_TOOLTIP:
      'Even if your bid does not win, you will receive a notification with information about the auction result for this lot',
    AGREEMENT_HINT1: 'Auction lot were chosen by myself. I agree to the',
    AGREEMENT_HINT2: '',
    BID_RULES: 'Bidding Rules',
    IN_ACCORDANCE_WITH: 'according to',
    NAVIS_RULES_HINT1: 'By placing a bid, I confirm that I have read and agree to the',
    NAVIS_RULES_HINT2: 'terms of the disassembly',
    NAVIS_RULES_TITLE: 'Cut rules',
    LIMIT: 'Limit',
    DOESNT_MAKE_SENSE: 'The odds of bidding on winning are too low, are you sure you want to bet?',
    NEED_PRIORITY_PASS: 'Order priority delivery',
    PRIORITY_PASS: 'Priority delivery',
    GREEN_CORNER: '“My yard“ mode',
    GREEN_CORNER_WARNING: 'Mode is not available with borderline age vehicles',
    DEFAULT_DELIVERY: 'Standard delivery',
    CAN_PACE_ONLY_ONE_BID:
      'There are less than 60 minutes left before the auction. You can place one bid without being able to raise it later.',
    IS_SANCTIONED_UTILIZATION_SURE: 'The bid condition is "Recycling". Are you sure?',
    WANT_PLACE_BID: 'do you want to place a bid?',
    CANT_CREATE_TRANSLATION:
      'Translation requests cannot be accepted when there is less than an hour left before the auction',
    PRIORITY_PASS_PARAGRAPHS: {
      '1': '3-4 недели ожидания от покупки на кнопке до получения во Владивостоке*',
      '2': 'Автомобиль и документы будут максимально быстро доставлены с аукциона, отправка первым же возможным рейсом в РФ (при наличии экспортного сертификата) и возможность забрать автомобиль с готовым ЭПТС без очереди на СВХ на следующий день после выпуска авто от офиса компании Прим-Брокер**.',
      '3': 'Стоимость услуги: 500$ + 50000 йен + 15000₽, оплата услуги в России при получении машины.',
      '4': 'Заказать данную услугу можно также в Личном Кабинете из карточки объекта в течении суток после выигрыша лота.',
      '5': 'Заказать данную услугу можно также в Личном Кабинете из карточки объекта в течении суток после выигрыша лота.',
      NOTES: {
        '1': '*За исключением случаев, когда сроки доставки не могут быть сокращены по независящим от нас причинам, связанным с введением санкций, с судоходством, плохими погодными условиями, с задержкой документов на транспортное средство со стороны аукциона.',
        '2': '**Услуга прохождения испытательной лаборатории, которая входит в экспресс доставку (Priority Pass), осуществляется только при соответствии автомобиля техническому регламенту. При наличии: рейлингов, фаркопа, нестандартного освещения, размера колёс и прочего нестандартного оснащения автомобиля, может последовать отказ в прохождении испытаний от лаборатории. В этом случае возможно увеличение сроков прохождения испытаний и стоимости услуги.',
      },
    },
    DISABLED_REASONS: {
      'data-changes': 'Lot data have changed',
      'moto-overprice': 'Lot withdrawn from auction',
      'time-over': 'Time has expired. Bets are no longer accepted',
      'bids-limit': 'Lot bid limit reached',
      'lots-limit': 'The lot limit with your bids has been reached',
      'chance-used': 'Last chance bid has been used',
    },
    GUIDE: {
      BID_AMOUNT: {
        TITLE: 'Bid amount',
        DESCR:
          'Enter desired bid amount. The system will tell you how high the chances of winning are with the specified amount',
      },
      BIDS_GROUPING: {
        ZERO: {
          TITLE: 'Action',
          DESCR: 'Click on "New group" button',
        },
        FIRST: {
          TITLE: 'Bids grouping',
          DESCR:
            'Combine bids into groups to increase your chances of purchasing a car. Example - you want to bid on 5 cars, but you only need to buy 2 of them.\n Create a group with any letter and number 2. Add the desired bets to this group. We will know that within the group you only need to buy 2 cars',
        },
        SECOND: {
          TITLE: 'Bids grouping',
          DESCR:
            'All lots in a group must have the same auction date. Otherwise, you risk purchasing extra cars. After at least one group has been created,\n you will be able to select them by interacting with new field to the left of the button.',
        },
        CLOSE: {
          TITLE: 'Action Remove',
          DESCR: 'Click on this button, to remove group',
        },
      },
      CALC: {
        TITLE: 'Calculator',
        DESCR:
          'The calculator will tell you the approximate costs for Japan logistics within the lot',
      },
      CONDITION: {
        TITLE: 'Condition',
        DESCR: 'Select the state in which the vehicle will be delivered to the destination port',
      },
      COMMENT: {
        TITLE: 'Comment',
        DESCR:
          'Leave a comment for us if you want to notify us about something important within the lot',
      },
      LOT_TRANS: {
        FIRST: {
          TITLE: 'Lot translation 1',
          DESCR:
            'Use the lot translation service to clarify information on the object. Choose the preffered translation language in the profile settings',
        },
        SECOND: {
          TITLE: 'Lot translation 2',
          DESCR:
            'As soon as the transfer arrives, you will need to familiarize yourself with it and confirm your participation in the auction. Otherwise,\n your bet will be invalid',
        },
      },
      NOTIFY_RES: {
        TITLE: 'Result notification',
        DESCR:
          'If you want to receive a notification about the results of trading, activate this item. The notification will be sent to you by email and Telegram\n if you have enabled it in the settings',
      },
      BIDS_RULES: {
        TITLE: 'Bids rules',
        DESCR:
          'To participate in the auction, you must read and accept the conditions for placing and processing bids',
      },
      GREEN_CORNER: {
        TITLE: '"Green corner" mode',
        DESCR:
          'Activate it if you want to buy a vehicle with "Green corner" mode. Contact your manager for more details',
      },
      FINISH: {
        TITLE: 'The briefing is over',
        DESCR: 'Thanks for your attention!',
      },
    },
  },
  ARTICLE: {
    ARTICLE_NOT_FOUND: 'Article not found',
    TRANSLATE_NOT_FOUND: 'Translation not found',
    CATEGORIES: 'Categories',
    OPEN_CATEGORY_MENU: 'Open category menu',
    ARTICLE_LOAD_ERROR: 'Load article fail',
    CATEGORIES_LOAD_ERROR: 'Load categories fail',
  },
  USER_REQUESTS: {
    LANGUAGE: 'Language',
    CREATE: 'Send request',
    CREATE_SUCCESS: 'Request successfully created',
    CREATE_FAIL: 'Failed to create a request',
    MESSAGE: 'Message',
    TRANSLATION_MEDIA_TYPE: 'Translation type',
    ALERT:
      'The lot will be translated on a first-come, first-served basis. Priority is given to lots with the closest auction dates',
    SATURDAY_TIME_LIMIT_ALERT:
      'Translation requests on on Saturdays are accepted until 11:00 Japan time',
    LOT_TRANSLATE_REQUEST: 'Request translation',
    BID_DELETE_REQUEST: 'Request bid deletion',
    BID_PLACE_COMMENT: 'Place comment',
    BID_DELETE_WARNING:
      'The request to delete a bid does not mean the actual cancellation of the bid, in some cases the bid cannot be canceled. It is not possible to delete bid after 17:00 on weekdays and 11:00 on Saturday Tokyo time.',
    BID_DELETE_WARNING2: 'Attention:',
    BID_DELETE_WARNING3:
      'if you want to delete all bids for this lot you need to make a request for each bid you made',
  },
  UPLOAD_PHOTOS: {
    SUCCESS: 'Photos successfully uploaded',
    FAIL: "Couldn't upload photo",
  },
  BID_COMMENTS: {
    PLACEHOLDER: 'Enter comment text',
    SEND: 'Send',
    YOU: 'You',
    MANAGER: 'Manager',
    SUCCESS: 'Comment added successfully',
    FAIL: "Couldn't add a comment",
    ENTER_COMMENT: 'Enter comment',
  },
  LOT_CAROUSEL: {
    DELETE_PHOTO: 'Are you sure delete this photo ?',
    DELETE_SUCCESS: 'Photo deleted successfully',
    DELETE_FAIL: 'Failed to delete photo',
    MOVE_LEFT: 'Move left',
    MOVE_RIGHT: 'Move right',
    SAVE_CHANGES: 'Save changes',
    DELETE_PHOTO_TOOLTIP: 'Delete photo',
    INSPECTION_FILES: 'Inspection files',
    AUCTION_LIST_TIP_MODAL: {
      TITLE: 'How to read auction sheets?',
      TIPS: {
        GENERAL: {
          LOT_NUM: 'Lot number',
          VEHICLE_PARAMS: 'Vehicle parameters',
          FIRST_REG_DATE: 'First registration date',
          ENGINE_CAPACITY: 'Engine capacity',
          MODEL_NAME: 'Model name',
          CAR_NAME: 'Car name',
          CAR_HISTORY: 'Car history',
          CHASSIS_MODEL: 'Chassis model',
          CHASSIS: 'Chassis',
          CHASSIS_MODIFICATION: 'Chassis modification',
          CHASSIS_NUM: 'Chassis number',
          CHASSIS_GRADE: 'Chassis grade',
          CHASSIS_TYPE: 'Chassis type',
          TECH_INSPECTION_DEADLINE: 'Tech inspection deadline',
          DOORS: 'Doors',
          MODEL_VERSION: 'Model version',
          DRIVETRAIN_TYPE: 'Drivetrain type',
          INTERIOR: 'Interior',
          INTERIOR_GRADE: 'Interior grade',
          EXTERIOR_GRADE: 'Exterior grade',
          MILEAGE: 'Mileage',
          GRADE: 'Grade',
          VEHICLE_COLOR: 'Vehicle color',
          ORIGINAL_COLOR: 'Original color',
          SHIFTER_LOCATION: 'Location of the gearshift knob',
          COLOR_CODE: 'Color code',
          INTERIOR_COLOR: 'Interior color',
          INTERIOR_SEATS: 'Interior, seats',
          YEAR: 'Year',
          MODEL_YEAR: 'Model year',
          IMPORT_TYPE: 'Dealer',
          IMPORT: 'Import',
          IMPORT_HISTORY: 'Import history',
          IMPORT_CLASS: 'Import classification',
          USE_TYPE: 'Use type',
          PERSONAL_RENT: 'Personal/Rent',
          MONTH_DAY: 'Month day',
          MONTH: 'Month',
          DAY: 'Day',
          STEERING_WHEEL: 'Steering wheel side',
          STEERING_WHEEL_CONDITION: 'Steering wheel condition',
          LEFT_RIGHT: 'Left/Right',
          TECH_INSP: 'Technical inspection',
          RECYCLING_FEE: 'Recycling fee',
          RECYCLE_TICKET: 'Recycling ticket',
          SEATS: 'Seats',
          SEATING_CAPACITY: 'Seating capacity',
          CARRYING_CAPACITY: 'Carrying capacity',
          TRANSMISSION: 'Transmission',
          ORIGINAL_EQUIPMENT: 'Original equipment',
          VEHICLE_DIMENSIONS: 'Vehicle dimensions',
          SUNROOF: 'Sunroof',
          LEATHER_INTERIOR: 'Leather interior',
          LEATHER_SEATS: 'Leather seats',
          SEATS_CONDITION: 'Seats condition',
          ALLOY_WHEELS: 'Alloy wheels',
          TV: 'TV',
          TV_ORIGINAL: 'TV-Original',
          AUDIO: 'Audio',
          WHEEL_COVERS: 'Wheel covers',
          BODY_KIT: 'Body kit',
          SIDE_MIRRORS: 'Side mirrors',
          ABRASIONS: 'Abrasions',
          NOT_WORK_ABSENT: 'Not work, Absent',
          FUEL_TYPE: {
            TITLE: 'Fuel type',
            DESCR: 'Gasoline is circled in this case, diesel to the right',
          },
          RE_REG_DEADLINE: {
            TITLE: 'Documents re-registration deadline',
            DESCR: 'Date by which the car must be re-registered',
          },
          WILL_BE_SENT_LATER: {
            TITLE: 'Will be sent later',
            DESCR: 'Additional items that will be sent',
          },
          DIMENSIONS: {
            TITLE: 'Dimensions',
            DESCR: 'Length-Width-Height',
          },
          PURCHASED_FROM_OWNER: {
            TITLE: 'Purchased from the owner',
            DESCR: 'Yes/No',
          },
          CONDITIONER: 'Air Conditioner',
          POWER_STEERING: 'Power steering',
          GPS: 'GPS',
          POWER_WINDOWS: 'Power windows',
          AIRBAG: 'Airbag',
          ABS: 'ABS',
          TUNER: 'Tuner',
          FW: 'FW',
          SELLER_NOTES: 'Seller notes',
          PLATE_NUM: 'Plate number',
          SERIAL_NUM: 'Serial number',
          NOTES: 'Notes',
          INSPECTOR_NOTES: 'Inspector notes',
          MARKED_AVAILABLE: 'Marked only if available',
          WINDSHIELD: 'Windshield',
          SCRATCHES: 'Scratches',
          REPLACEMENT_REQUIRED: 'Replacement required',
          LENGTH: 'Length',
          WIDTH: 'Width',
          HEIGHT: 'Height',
          DAMAGE_SCHEME: {
            TITLE: 'Damage scheme',
            DESCR: 'You can find out more about damage marks by',
            LINK: 'following this link',
          },
          SPARE_WHEEL: 'Spare wheel',
          INSURANCE: 'Insurance',
          NEW_CAR_INSURANCE: 'New car insurance',
          JP_DOCS: 'Japanese documents',
          DEALER_SELF_IMPORT: 'Dealer/Import',
          CAR_OPTIONS: 'Car name/Doors/Chassis type/Version',
          A_SCRATCHES: 'A - scratches',
          X_CRACKS_BROKEN: 'X – cracks, broken',
          A_SCRATCHES_BROKEN: 'A – scratches, broken',
          SCRATCHES_NEED_REPLACE: 'Scratches・Needs replacement',
          SERVICE_BOOK: {
            TITLE: 'Service book',
            DESCR: 'Yes/No',
          },
          COLOR_DESCR_1: '元色 - original color',
          COLOR_DESCR_2: '色替 - repainted',
        },
        USS: {
          CAR_HISTORY: {
            TITLE: 'Car history',
            DESCR: 'Was it used by a private person or was it rented, or another option',
          },
          FIRST_REG_JP_CHRONO: 'First registration acc. to Japanese chronology',
          TECH_INSPECTION_VALIDITY_PERIOD: {
            TITLE: 'Technical inspection validity period',
            DESCR:
              'The date until which the vehicle inspection ticket is valid in Japan. Issued to a new car for 3 years',
          },
          CONDITIONER: {
            TITLE: 'Air conditioner',
            DESCR:
              'In this case, this is an air conditioner plus a filtration and heating system, equipped with several sensors and an electronic climate control unit',
          },
          DOCS_VALIDITY_PERIOD:
            'The validity period of the documents before which the purchased car must be re-registered. This is only relevant for cars with license plates',
        },
        T_C_AA: {
          LOT_NUM: {
            TITLE: 'Lot number',
            DESCR:
              'The mark under the lot number shows whether the car is at auction for the first time or not',
          },
          TAA_AUCTION_NOTES: 'TAA auction notes',
          CAA_AUCTION_NOTES: 'CAA auction notes',
          CAR_COLOR_DESCR: 'Mark to the right if the car been repainted',
        },
        JU: {
          MODEL_VERSION: 'Model/version',
          COLOR_N_COLOR_CODE: 'Vehicle color and color code',
          CAR_DISADVANTAGES_WINDSHIELD:
            'Scratches, stone chips, cracks, signs of repair, replacement required',
          CAR_DISADVANTAGES_INTERIOR: 'Scratches, dirt, burns, holes, abrasions, cuts, torn',
        },
        LAA: {
          DEALER_INSURANCE: 'Dealer insurance',
          OWNERS_MANUAL: "Owner's manual",
          INTERIOR_OPTIONS: 'Scratches • Smoked • Holes • Dirt • Torn',
          KEYS_OFFICE: {
            TITLE: 'Keys are at the auction office',
            DESCR: 'If checked, keys are stored at the auction office. Car is closed',
          },
        },
        ARAI: {
          RIGHT_STEERING_WHEEL: 'Right side steering wheel',
          INSPECTOR_NOTES_DESCR_1: 'First line - Information whether the car is running or not',
          INSPECTOR_NOTES_DESCR_2:
            'Second line - Information about car pickup if the car is in another yard',
          INSPECTOR_NOTES_DESCR_3: 'Other inspector notes',
        },
        HERO: {
          NAME_MODEL: 'Car name and model version',
          WARNING: 'Warning!',
          G_GASOLINE: 'G-gasoline',
          YEN: 'Yen',
        },
        IAA_OSAKA: {
          ODOMETER_PROBLEMS: {
            TITLE: 'Odometer notes',
            DESCR: 'Replaced - Mileage twisted - Mileage unknown',
          },
          NOX: {
            TITLE: 'NOx(nitrogen oxide)',
            DESCR: 'Meets or does not meet standards',
          },
          SELLER_TYPE: {
            TITLE: 'Import classification',
            DESCR: 'Dealer-Import',
          },
          X_OPTIONS: 'X – chips, cracks, needs replacement',
          CAR_DISADVANTAGES: 'Spots, cracks, scratches, dirt, abrasions',
          SCRATCHES_ABRASIONS: 'Scratches-Abrasions',
        },
        MIRIVE: {
          INNER_AUCTION_NAME: 'Inner auction name',
          REGISTRATION: 'Registration',
          PRECAUTIONARY_MEASURES: 'Precautionary measures',
          RE_REG_PERIOD: 'Re-registration period',
          BUY_CAR: 'Buying a car',
          DEALER_MILEAGE: 'Dealer ・ Mileage',
          LEFT_RIGHT_STEERING_WHEEL: 'Right or left hand drive',
          WHEELS_SCRATCHES_BROKEN: 'Door mirrors (scratches/broken)',
          REAR_VIEW_MIRRORS: 'Body condition (minor scratches/minor dents/needs repairs)',
          ADDITIONALLY: 'Additionally',
        },
      },
      BUTTON: {
        TOOLTIP_TITLE: 'Click for auction listing details',
      },
    },
  },
  LOT_STATISTICS: {
    ENGINE_VOLUME: 'Engine, cc',
    ALERT:
      'It is possible that the current lot was previously auctioned, you can check in the list',
  },
  LOT_INFO: {
    TRUCK: 'Truck',
    TITLE: 'Lot',
    NAME: 'Name',
    AUCTION: 'Auction',
    AUCTION_DATE: 'Auction date',
    YEAR: 'Year',
    CHASSIS_ID: 'Chassis ID',
    COLOR: 'Color',
    ENGINE_CC: 'Engine, cc',
    MILEAGE: 'Mileage',
    INSPECTION: 'Inspection',
    SCORE: 'Score',
    START_PRICE: 'Start',
    SOLD_FOR: 'Sold for',
    TRADING_RESULT: 'Trading result',
    AVERAGE_PRICE: 'Average price',
    YOUR_BID: 'You bid',
    RECOMMENDED_BID_AMOUNT: 'Recommended bid amount',
    RECOMMENDED_BID_NO_FRESH_DATA: 'No recent data',
    APPROVE_PARTICIPATION: 'Approve my participation',
    DENY_PARTICIPATION: 'Cancel the bid',
    PARTICIPATION_ACCEPTED: 'Participation approved',
    PARTICIPATION_CANCELED: 'Participation canceled',
    NEGOTIATIONS_PRICE: 'Negotiations start price',
    APPROVE_PARTICIPATION_IN_NEGOTIATIONS: 'Approve participation',
    NEGOTIATIONS_ACCEPTED: 'Negotiations is approved',
    COUNTDOWN_TO_FINISH: 'Countdown to finish',
    WAS_OVERBID: 'You are not a leader',
    END_SOON_HINT: 'The acceptance of bids on the lot is over!',
    BIDDING_OVER: 'The bidding is over!',
    FIXED_PRICE: 'Price',
    BEST_BID: 'Another participant bid',
    PRODUCTION_DATE: 'Production date',
    EXISTS: 'Exists',
    NOT_EXISTS: 'Not exists',
    DRIVE: 'Wheel drive',
    GROUP: 'Group',
    GEARBOX_TYPE: 'Transmission type',
    OBJECT_DATA: 'Lot info',
    BIDS: 'Bids',
    GUIDE: {
      MY_BIDS: {
        TITLE: 'Bids page',
        DESCR:
          'This is your bids page. Here you can see your current and old bids and cancel any of the current bids',
      },
      FILTER: {
        TITLE: 'Filter',
        DESCR: 'Use the filter to find the required bids according to the specified parameters',
      },
      STATUS: {
        TITLE: 'Status',
        DESCR: 'Use this tabs to navigate between specific bids depending on their status',
      },
      BIDS_LIST: {
        TITLE: 'Bids list',
        DESCR: 'Your bids will be displayed here based on the above parameters',
      },
      COMMENT: {
        TITLE: 'Comment',
        DESCR: 'You can leave comment with any important information for us',
      },
      DELETE_BID: {
        TITLE: 'Bid cancellation',
        DESCR:
          'You can leave us a request to remove your bid if you do not want to participate in the auction.\n Please note that if you have several bids for one lot, then you need to send a removal request for each of them',
      },
      FINISH: {
        TITLE: 'The briefing is over',
        DESCR: 'Thanks for your attention!',
      },
    },
  },
  GEARBOX_TYPES: {
    automatic: 'Automatic',
    manual: 'Manual',
  },
  LOT_PHOTO_REQUEST: {
    SEND: 'Request photos',
    SUCCESS: 'Request was sent successfully',
    FAIL: 'The request could not be sent',
    ARAI_SUCCESS: 'Photos uploaded successfully',
    ARAI_FAIL: 'Failed to upload photo',
  },
  PRODUCTION_DATE_PAYLOAD: {
    date: 'Production date',
    trimcode: 'Code of upholstery',
    modelname: 'Model',
    modelcode: 'Model Code',
    engineno: 'Engine',
    colorcode: 'Colour code',
    code: 'Description',
    catalogno: 'Catalogue number',
    transcode: 'Transmission',
    vincode: 'Vin number',
    modelcat: 'Category',
    blockcode: 'Engine info',
    gradecode: 'Grade',
    colorrgb: 'Color RGB',
    colorname: 'Color name',
  },
  NOTIFICATION_SETTINGS: {
    TITLE: 'Notifications',
    FULL_TITLE: 'Notifications settings',
    NAME: 'Notification name',
    CONNECT_TELEGRAM: 'Connect notifications via Telegram',
    TELEGRAM_NOT_CONNECTED: 'Telegram account not connected',
    NAMES: {
      AkebonoBidBought: 'Lot bought',
      AkebonoBidTranslationCompleted: 'Lot translation complete',
      AkebonoBidWasOverbid: 'Bid was overbid',
    },
  },
  BIDS_SETTINGS: {
    TOOLTIPS: {
      HIDE_MY_BIDS_AMOUNT_HINT:
        'When enabled, other users will not see amount of your bid, but you will not be able to see amount of the bid of other users either.',
      HIDE_BIDS_ATTENTION: 'Attention! You can change this setting only once.',
    },
    KEYS: {
      hideAmountBid: 'Hide amount of my bid',
      bidTranslationByDefault: 'Request lot translation at bid',
      subscribeToViewBestBid: 'Show another clients bid',
      ignoreSmallBidWarning: 'Ignore the warning about the low chance of winning the bid',
    },
  },
  TRANSLATION_SETTINGS: {
    DEFAULT_TRANSLATION_MEDIA_TYPE: 'Default translation type',
    DEFAULT_AUDIO_TRANSLATION_SPEED: 'Default translation playback speed',
    PREFERRED_LANGUAGE_FOR_TRANSLATION_AND_NOTIFICATION:
      'Preferred language for translation and notifications',
    TRANSLATION_MEDIA_TYPES: {
      AUDIO: 'Audio',
      TEXT: 'Text',
    },
  },
  SETTINGS: {
    NAME: 'Name',
    VALUE: 'Value',
    TITLE: 'Settings',
    NOTIFICATIONS: 'Notifications',
    BIDS: 'Bids',
    OTHER: 'Other',
    TRANSLATIONS: 'Translations',
  },
  LOT_TRANSLATION: {
    ICONS: {
      damaged: 'Malfunction',
      forklift: 'Not drivable',
      repainted: 'Repainted',
      check: 'Check engine',
      equipped_for_disabled: 'Accessible van',
      drowned: 'Flooded car',
      sanctions: 'Sanctions',
      dashboard_lamp_on: 'Dash lamp on',
      left_drive: 'Left drive',
      second_keys: 'Second key',
      diesel: 'Diesel vehicle',
      natural_gas: 'Natural gas vehicle',
    },
    ICONS_TOOLTIPS: {
      damaged: 'Car malfunction. Contact your manager',
      forklift: 'Can’t move under its own power',
      repainted: 'Not original paint',
      check: 'Check engine indicator is on',
      equipped_for_disabled: 'Equipped with a wheelchair ramp or powered lift',
      drowned: 'Flooded car',
      sanctions: 'The car is on the sanctions list',
      dashboard_lamp_on: 'One or more dashboard lights are on',
      left_drive: 'The car is left-hand drive',
      second_keys: 'Has second key',
      diesel: 'Diesel vehicle',
      natural_gas: 'Natural gas vehicle',
    },
  },
  SPECIAL_BID_ALERT: {
    ONE: {
      FIRST_LINE: 'Dear customers!',
      SECOND_LINE:
        'Due to increase of gasoline prices in Japan, our expenses in the calculator have changed. Please familiarize yourself with the new tariffs.',
    },
    TWO: {
      SECOND_LINE:
        'We remind you that the purchased lot must be paid within 3 working days. If the payment for the lot you purchased does not arrive to our account in Japan within 10 days, you will be awarded a penalty of 1% of the amount owed daily until the debt is repaid. The exception is when the payment is subject to verification in a Japanese bank.',
    },
  },
  LOT_STATUSES: {
    STATUS: 'Lot status',
    INITIAL: 'Unhandled',
    SOLD: 'Sold',
    NEGOTIATIONS: 'Negotiations',
    WON: 'Purchased',
    CANCELED: 'Removed by auction',
    UNSOLD: 'Unsold',
    NEGOTIATION_SOLD: 'Sold by negotiation',
    REMOVED: 'Removed',
  },
  BOUNDARY_INFO: {
    NOT_BOUNDARY: 'Import to the Russian Federation before {{date}}',
    EARLY: 'Import to the Russian Federation after {{date}}',
    BOUNDARY: 'Boundary! Import to the Russian Federation before {{date}}',
    OVERDUE: 'Impassable',
  },
  INTERESTING: {
    TRUE: 'Add a lot to favorites',
    FALSE: 'Mark the lot as not interesting to me',
  },
  SPECIAL_ALERTS: {
    HAS_DEBTS:
      'We kindly ask you to inform us about the payment of the car in your personal account on the day of the bank transfer. Otherwise, your payment may go unidentified and a penalty will be awarded to the car.',
  },
  SHARED: {
    SHARE: 'Share',
    SHARE_LOT: 'Share a lot',
    SHARE_LIST: 'Share a list',
    COPY_SHARED_SUCCESS: 'The link to the lot has been copied to the clipboard',
    COPY_LIST_SUCCESS: 'The link to the list has been copied to the clipboard',
    OPEN: 'Open',
    CLOSE: 'Close',
    SELECT_LIST: 'Select list',
    ADD_TO_SELECTION: 'Add to selection',
    ADD_NEW_LIST: 'Add new list',
    ADD_NEW_LISTS: 'New selection',
    LISTS_INCLUDES: 'The lot is included in the lists',
    LIST_ADD: 'Add',
    MOVE: 'Move',
    MOVE_TO: 'Move to',
    DELETE_LIST: 'Delete list',
    CLOSE_ACCESS: 'Close access',
    CHANGE_SELECTION: 'Rename selection',
    VIEW_ALL: 'View all',
  },
  CHANNEL: {
    LABEL: 'Channel',
    DELETE: 'Delete channel',
    EDIT: 'Edit channel',
    CREATE: 'Create channel',
    CHANNEL_TITLE: 'Channel name',
    COLLECTION_TITLE: 'Collection name',
    COMPANY_NAME: 'Company name',
    DESCRIPTION: 'Description',
    UPLOAD_LOGO: 'Upload logo',
  },
  MANUAL_LOTS: {
    LABEL: 'Additionally',
    UPLOAD: 'Upload photo',
    CREATE: 'Create lot',
    ADD_LOT: 'Add lot',
  },
  FILTER: {
    FILTER: 'Filter',
    RESET: 'Reset',
    SEARCH: 'Search',
    REQUIRED: 'Required',
  },
  MILEAGE_FILTER: {
    FROM: 'Mileage from',
    TO: 'Mileage to',
  },
  YEAR_FILTER: {
    FROM: 'Year from',
    TO: 'Year to',
  },
  LOT_CHANGES: {
    TITLE: 'Lots change history',
    DATE: 'Date',
    DESCRIPTION: 'Description',
    VERSION: 'Version',
    AUTHOR: 'Author',
    CHANGES: {
      CREATED: 'Lot created',
      TRANSLATIONS_COUNT: 'Translation count changed:',
      ICONS: 'Icons changed:',
      VIN: 'Vin changes:',
      PRODUCTION_DATE: 'Production date changed:',
      IMAGES: 'Images changed. Current count:',
      THUMBNAILS: 'Thumbnails changed. Current count:',
      PHOTOS_FETCHED: 'Lot photos fetched',
      STATUS: 'Status changed:',
      UPDATED: 'Lot updated',
      START_PRICE: 'Start price changed:',
      SOLD_PRICE: 'Sold price changed:',
      NEGOTIATIONS_PRICE: 'Negotiations price changed:',
      AUCTION_DATETIME: 'Auction datetime updated:',
    },
    BUTTON_TITLE: 'Lot history',
  },
  ARAI_KENKI: {
    LABELS: {
      EXCAVATOR: 'Excavator',
      MINI_EXCAVATOR: 'Mini excavator',
      WHEEL_LOADER: 'Wheel loader',
      MINI_WHEEL_LOADER: 'Mini wheel loader',
      SKID_STEER_LOADER: 'Skid steer loader',
      GRADER: 'Grader',
      ASPHALT_FINISHER: 'Asphalt finisher',
      CRANE: 'Crane',
      ROUGH_TERRAIN_CRANE: 'Rough terrain crane',
      TRUCK_CRANE: 'Truck crane',
      ALL_TERRAIN_CRANE: 'All terrain crane',
      AERIAL_WORK_PLATFORM: 'Aerial work platform',
      CRAWLER_CRANE: 'Crawler crane',
      MINI_CRAWLER_CRANE: 'Mini crawler crane',
      COMPRESSOR: 'Compressor',
      MOBILE_CRUSHER: 'Mobile crusher',
      ATTACHMENTS: 'Attachments',
      ATTACHMENTS_ETC: 'Attachments etc',
      BLADE: 'Blade',
      BUCKET: 'Bucket',
      BREAKER: 'Breaker',
      CUTTER: 'Cutter',
      CRUSHER: 'Crusher',
      CONCRETE_CUTTER: 'Concrete cutter',
      CONSTRUCTION_MISCELLANEOUS: 'Construction miscellaneous',
      VIBRO: 'Vibro',
      VIBRO_HAMMER: 'Vibro hammer',
      FORK: 'Fork',
      FORKLIFT_NAIL: 'Forklift nail',
      FLOODLIGHT: 'Floodlight',
      MAGNET: 'Magnet',
      PLATE: 'Plate',
      RAMMER: 'Rammer',
      BULLDOZER: 'Bulldozer',
      SHOVEL_LOADER: 'Shovel loader',
      FORKLIFT: 'Forklift',
      BATTERY_FORKLIFT: 'Battery forklift',
      REACH_FORKLIFT: 'Reach forklift',
      RICE_TRANSPLANTER: 'Rice transplanter',
      ROLLER: 'Roller',
      COMBINE_ROLLER: 'Combined roller',
      SOIL_ROLLER: 'Soil roller',
      TIRE_ROLLER: 'Tire roller',
      TANDEM_ROLLER: 'Tandem roller',
      HAND_GUIDED_ROLLER: 'Hand guided roller',
      MACADAM_ROLLER: 'Macadam roller',
      CRAWLER_DUMP: 'Crawler dump',
      CRAWLER_CARRIER: 'Crawler carrier',
      CRAWLER_TRUCK: 'Crawler truck',
      WHEEL_CARRIER: 'Wheel carrier',
      GENERATOR: 'Generator',
      WELDER_GENERATOR: 'Welder generator',
      AGRICULTURAL_MACHINE_ETC: 'Agricultural machine etc',
      CONSTRUCTION_MACHINE_ETC: 'Construction machine etc',
      OFFROAD_DUMP_TRUCK: 'Offroad dump truck',
      SNOW_REMOVING_MACHINE: 'Snow removing machine',
      STABILIZER: 'Stabilizer',
      SPECIAL_PURPOSE_VEHICLE: 'Special purpose vehicle',
      ROAD_CUTTER: 'Road cutter',
      TURRET_TRUCK: 'Turret truck',
      LOGGING_TRACTOR: 'Logging tractor',
      TOWING_TRACTOR: 'Towing tractor',
      SMALL_MACHINES: 'Small machines',
      TRACTOR: 'Tractor',
      COMBINE: 'Combine',
      AGRICULTURAL_MACHINE_ATTACHMENT: 'Agricultural machine attachment',
    },
  },
  PRESET_UNSUBSCRIBE: {
    UNSUBSCRIBING: 'Unsubscribing...',
    SUCCESS: 'Unsubscribe from the mailing list of collections is completed',
    FAIL: 'Filter preset not found',
    GO_HOME: 'Go home',
  },
  LOT_PROBLEM_ALERTS: {
    LOT_DATA_CHANGED: 'Lot changed, bids are not accepted',
    BIDS_LIMIT_REACHED: 'The daily lots limit has been reached',
    LOT_BIDS_LIMIT_REACHED: 'The limit of bids on the lot has been reached',
  },
  CONNECT_TELEGRAM: {
    TITLE: 'Connecting Telegram',
    CONNECT_BUTTON: 'Connect notifications via Telegram',
  },
  SUBSCRIBE_TO_BEST_BID: {
    CONFIRMATION_1:
      'The "Bid Display" service allows you to see the current bid for another clients lot within 30 days. To request this service it is needed to make 10 or more purchases in the last 30 days',
    CONFIRMATION_2:
      'The cost is <span class="red">100,000 yen</span>, which will be deducted from your advance.',
    ATTENTION: 'Attention!',
    OK_TEXT: 'Plug',
    TITLE: 'Show other clients bid',
    DAYS_LEFT: '{{days}} days left',
    NO_ACTIVE_SUB: 'No active subscription',
    HAS_ACTIVE_SUBSCRIPTION: 'Has active subscription to view best bid',
    SUCCESS: 'The service is successfully connected.',
    SUCCESS_WHEN_ADVANCE_UPDATE:
      'The service will be activated after some time, when the advance payment arrives',
    SUCCESS_REQUEST:
      'The request to activate the subscription when the advance payment is received is created',
    FAIL: 'Error when connecting the service. Contact your manager.',
    MODAL_TITLE: 'Agreement',
    AGREEMENT_TIMER: 'You can activate the service via {{seconds}}',
    AGREE: 'I agree with the terms of connection and the cost',
    SUB_ENDS_TODAY: 'Subscription ends today',
    SUBSCRIBE_ON_ADVANCE_UPDATE: 'Plug when the advance payment arrives',
    REQUEST_SUBSCRIBE_ON_ADVANCE_UPDATE: 'Request plug when the advance payment arrives',
    CANT_BUY_ALERT: 'You do not have enough funds in advance to purchase the service',
  },
  LOT_COUNTERS: {
    VIEWS_COUNT: 'Views count: {{count}}',
    IN_FAVORITES_COUNT: 'Added to favorites: {{count}}',
    TOOLTIP: '{{bidders}} customers have placed {{bids}} bids on this lot',
    BIDDERS_ENDING_1: '',
    BIDDERS_ENDING_2: '',
    BIDDERS_ENDING_5: '',
    BIDS_ENDING_1: '',
    BIDS_ENDING_2: '',
    BIDS_ENDING_5: '',
    MAKE_ENDING_1: '',
    MAKE_ENDING_2: '',
  },
  MAX_BID_COMMISSION: {
    COMMISSION: 'Commission',
    YOU_BID_ON_LOT: 'Your bet on this lot will be',
    ADDITIONAL_DEALER_COMMISSION: 'Additional dealer commission',
    AGREE_WITH_COMMISSION: 'I agree with the additional commission',
    MINIMUM: 'Minimum',
    MINIMUM_DEALER_COMMISSION: 'Minimum dealer commission',
    MINIMUM_LOT_BID: 'Minimum bid amount',
  },
  BIRTHDATE_MODAL: {
    TITLE: 'Date of birth',
    INVALID_DATE: 'Not real date of birth',
    ALERT: 'To continue, you must specify the date of birth in the year-month-day format',
  },
  RESET_LOT: {
    TITLE: 'Update lot data',
    WARNING:
      'Updating the lot data will delete: lot bids, bid comments, user requests related to bids, user requests related to the lot, lot translations. Are you sure you want to update the lot?',
  },
  AUCTION_RATING: {
    REVIEWS: 'Reviews',
    REVIEWS_AUCTION: 'Reviews about {{auctionName}}',
  },
  USER_PURCHASE_STATISTICS: {
    BOUGHT_TODAY: 'Покупки сегодня',
    BOUGHT_YESTERDAY: 'Покупки вчера',
    BOUGHT_WEEK: 'Покупки неделя',
  },
  VAT: 'Attention! This purchase will be taxed at 10% VAT',
  MENU: 'Menu',
  NEWS: 'News',
  TO_FULL_LOT: 'Full lot',
  Целая: 'Whole',
  Спецтехника: 'Special machinery',
  Мотоцикл: 'Motorcycle',
  SANCTIONED_LOT_NOT_ALLOWED: 'Sanctioned lots information is unavailable for your country',
  'Request already created for this lot': 'Request already created for this lot',
  'Приём ставок на лот окончен!': 'The acceptance of bids on the lot is over!',
  'Конструктор 3 000 евро': 'Constructor 3 000 euros',
  'Запчасти (палеты)': 'Parts (pallets)',
  'Запчасти контейнер': 'Parts (container)',
  'Другие страны': 'Other countries',
  'Другие страны MOTO': 'Other countries only motorcycle',
  'Грузовик-конструктор': 'Truck-constructor',
  '(Борт) Распил перед раму пилим': '(Side) Sawed in front of the frame saw',
  '(Борт) Распил передний': '(Side) Sawed in front of the frame',
  '(Борт) Распил зад раму пилим': '(Side) Sawed in back of the frame saw',
  '(Борт) Распил задний': '(Side) Sawed in back of the frame',
  '(Борт) Распил вместе с рамой': '(Side) Sawed together with the frame',
  '(Борт) Передняя половина без крыльев': '(Side) Front half without wings',
  '(Борт) Конструктор': '(Side) Constructor',
  '(Борт) каркас': '(Side) frame',
  '(Берег) Распил перед раму пилим': '(Coast) Sawed in front of the frame saw',
  '(Берег) Распил передний': '(Coast) Sawed in front of the frame',
  '(Берег) Распил зад раму пилим': '(Coast) Sawed in back of the frame saw',
  '(Берег) Распил задний': '(Coast) Sawed in back of the frame',
  '(Берег) Распил вместе с рамой': '(Coast) Sawed together with the frame',
  '(Берег) Передняя половинка': '(Coast) Front half',
  '(Берег) Конструктор': '(Coast) Constructor',
  '(Берег) Каркас': '(Coast) frame',
  'Сумма ставки должна быть положительной': 'The bet amount must be positive',
  'Сумма ставки должна быть выше стартовой цены':
    'The bid amount must be higher than the starting price',
  'Ваша ставка меньше ставки другого участника торгов': {
    '': 'Your bid is less than the bid of another bidder.',
    ' Вы можете поднять свою ставку, попыток осталось:':
      'Your bid is less than the bid of another bidder. You can raise your bid, tries left: {{triesLeft}}',
  },
  LowBidError:
    'Your bid is lower than a bid by another participant OR Another participant bade a higher amount',
  Error: 'Error',
  'Слишком много ставок выше вашей, ставка не имеет возможности победить, извините':
    'Too many bids are higher than yours, the bid has no way to win, sorry',
  'Для данного лота не осталось попыток повышения ставки':
    "You've reached limit of bids for this lot",
  'Данные лота устарели, обновите страницу': 'Lot data is out of date, please reload the page',
  'В данном лоте уже есть победитель': 'This lot is already sold',
  'No Result From API': 'It was not possible to determine the date of production',
  'Incorrect Chassis No': {
    '': 'Incorrect Chassis number',
  },
  'Chassis number entered in not correct': {
    ' Check on auction sheet to confirm the chassis number': {
      '': 'Chassis number entered in not correct. Check on auction sheet to confirm the chassis number.',
    },
  },
  'Недостаточно прав для выполнения действия':
    'Insufficient permissions. Only users with access to bids can perform this action. Please ask your manager for access.',
  'Среднее время доставки с данного аукциона до:': 'Average delivery time from this auction:',
  'Среднее время доставки документов с данного аукциона:':
    'Average documents delivery time from this auction:',
  'в зависимости от доставщика': 'depends on deliverer',
  'У вас нет прав для перепроверки даты выпуска':
    'You dont have the rights to recheck the release date',
  дней: 'days',
  'Если это Ваш платеж пожалуйста срочно отзовитесь':
    'If this is your payment, please contact your manager ASAP',
  'Request for this lot already exists': 'Request for this lot already exists',
  Производитель: 'Maker',
  Модель: 'Model',
  Кузов: 'Frame',
  Аукцион: 'Auction',
  Оценка: 'Score',
  Цвет: 'Color',
  КПП: 'Gearbox',
  'год от, до': 'year from, to',
  'Пробег от, до (тыс': {
    'км)': 'Mileage (1000km)',
  },
  'Объем двигат': {
    ' от, до': 'Engine vol. from, to',
  },
  Сброс: 'Reset',
  Поиск: 'Search',
  PRINT: 'Print',
  'Расширенный поиск': 'Advanced search',
  Дополнительно: 'Extra',
  'Японские автоаукционы': 'Auctions',
  SAVE_PRESET: 'Save preset',
  CREATE_USER_FILTER_PRESET: 'Save filter preset',
  MARKED_INTERESTING: 'Interesting',
  MARKED_UNINTERESTING: 'Uninteresting',
  'Not Sold': 'Not sold',
  成約: 'Sold',
  流れ: 'Unsold',
  商談成約: 'Sold by negotiation',
  'Request already created': 'Request already created',
  'Lot not found': 'Lot photos not found',
  'Filter preset not found': 'Filter preset not found',
  'Достигнут лимит ставок в этой группе': 'The limit of bids in this group has been reached',
  BIDS: 'Bids',
  USER: 'User',
  LOT_BIDS_TABLE_TITLE: 'Bids total: {{bidsTotal}}. Users total: {{usersTotal}}',
  DETAILS: 'Details',
  NEGOTIATIONS_CONFIRM_1:
    'You are entering negotiations for the lot. This action cannot be undone. Are you sure you want to proceed? <br /> {{link}}',
  NEGOTIATIONS_CONFIRM_2:
    'MInimum bid required to enter negotiations is {{sum}}. An additional bid of ¥20,000 is necessary to increase chances in the bidding. The bid to enter negotiations cannot be lower than the proposed amount. Please, in the field below, indicate the bet amount not lower than {{sum}}',
  NEGOTIATIONS_WORK: 'How lot negotiations work',
  NEGOTIATIONS_BID_TOO_SMALL_ERROR: 'The amount of the bid cannot be less than {{amount}}',
  NEGOTIATIONS_BID_TOO_LARGE_ERROR: 'The amount of the bid cannot be more than {{amount}}',
  NEGOTIATIONS_BID_REQUIRED_ERROR:
    'It is necessary to specify the amount of the bid for negotiations',
  'Участие в торгах': 'Participation',
  'Ожидает подтверждения для участия в торгах': 'Awaits participation confirmation',
  Отклонено: 'Participation rejected',
  'Подтверждено участие в торгах': 'Participation accepted',
  TICKET_RULES: 'Auction claim rules',
  EXPECTED_TIME_TO_TRANSLATE: 'Average translation time for this auction in Japan working hours',
  'Номер лота': 'Lot No.',
  'Please select your country': 'Please select your country',
  'Cant plug subscription to view best bid': 'Couldnt activate subscription to view the best bid',
  PRODUCTION_DATE_CHECK_ERROR: 'Не удалось проверить дату производства',
  'information not found': 'Данные отсутствуют в имеющихся каталогах',
  'company not found': 'Данные отсутствуют в имеющихся каталогах',
  PRODUCTION_DATE_CHECK_REQUEST_LIMIT_REACHED: 'Production check request limit has been reached',
  NO_TARGET_AUCTION_TOOLTIP: 'Please contact your manager if the required auction in not listed',
  NOTATIONS: 'Notations',
  PROFILE: {
    USER_PROFILE: 'User profile',
    GENERAL: 'General',
    TIMEZONE: 'Time zone',
    UPDATE: 'Update',
    FOR_CHAT: 'for chat notifications',
    SECURITY: 'Security',
    PASSWORD_RULES:
      'The password must contain only Latin letters, one capital letter, one number and be at least 8 characters',
    ENTER_OLD_PASS: 'Enter old password',
    ENTER_NEW_PASS: 'Enter new password',
    REPEAT_NEW_PASS: 'Repeat new password',
    FIELD_REQUIRED: 'Field "{{field}}" is required',
    RULES_ERROR: 'Conditions not met',
    PASS_MISSMATCH: 'Password mismatch',
    CHANGE: 'Change',
    LANGUAGE: 'Language',
    EMAIL_INCORRECT: 'Incorrect email',
    ADD_EMAIL: 'Add email',
    VALIDATION_FAIL: 'Some fields are filled out incorrectly',
    UPDATE_SUCCESS: 'Profile updated successfully',
    UPDATE_FAIL: 'Failed to update profile',
    TOO_SHORT: 'Password is too short',
    ONLY_LATIN: 'Password must contain only latin letters',
    CAPITAL_CHAR: 'Password must contain at least one capital letter',
    ONE_DIGIT: 'Password must contain at least one number',
    CHANGE_PASS_REQUIRED: 'You must change your password',
    LANGUAGES: {
      ru: 'Rissian',
      en: 'English',
    },
    SUBSCRIBE_TO: 'Enable notifications via {{name}}',
    NOTIFICATION_BY: 'Notifications from {{name}}',
    SUBSCRIBE: 'Connect',
    NOTIFICATION_TYPE: 'Notification type',
    NOTIFICATIONS: 'Notifications',
    CONNECT_SUBSCRIBE: 'Enable notifications via Telegram',
    DELETE_NOTIFICATION_CHAT: 'Delete connected Telegram',
    CANCEL_DELETE_NOTIFICATION_CHAT: 'Cancel',
    CONFIRM_DELETE_NOTIFICATION_CHAT: 'Delete',
  },
  AUTO_FILTER_GUIDE: {
    '0': {
      TITLE: 'Day of the week',
      TEXT: 'You can select the desired auction day',
    },
    '1': {
      TITLE: 'Manufacturer',
      TEXT: 'Select the car manufacturer to narrow down the list of available models. This will help you quickly find cars from your favorite brand',
    },
    '2': {
      TITLE: 'Model',
      TEXT: 'Choose a specific car model after selecting the manufacturer',
    },
    '3': {
      TITLE: 'Frame',
      TEXT: 'Specify the desired frame',
    },
    '4': {
      TITLE: 'Auction',
      TEXT: 'If you are interested in a specific auction, you can specify it',
    },
    '5': {
      TITLE: 'Car score',
      TEXT: 'Auction car score. You can find out more about it via F.A.Q. Link is on top of the website',
    },
    '6': {
      TITLE: 'Color and gearbox',
      TEXT: 'Specify desired vehicle color and gearbox type',
    },
    '7': {
      TITLE: 'Year, mileage and displacement',
      TEXT: 'Engine displacement and mileage must be indicated in numbers, for example: 1500 or 75000',
    },
    '8': {
      TITLE: 'Lot numbers',
      TEXT: 'If you are interested in a specific lot, you can indicate its number here',
    },
    '9': {
      TITLE: 'Truck type',
      TEXT: 'You can search for trucks by specific types. For example - a crane, a garbage truck, a bus, a concrete mixer, etc.',
    },
    '10': {
      TITLE: 'Model grade',
      TEXT: 'Specify desired model grade',
    },
    '11': {
      TITLE: 'Wheel drive',
      TEXT: 'You can filter cars with required wheel drive',
    },
    '12': {
      TITLE: 'Production date',
      TEXT: 'Search by production date is applied only to those lots for which we have this data',
    },
    '13': {
      TITLE: 'Auction has not started yet',
      TEXT: 'Show only auctions where bidding has not yet started',
    },
    '14': {
      TITLE: 'Save preset',
      TEXT: 'Once you have applied a filter, you can save it so that you can apply it in the future with one click',
    },
    '15': {
      TITLE: 'Filter actions',
      TEXT: 'Reset or apply your current filter settings',
    },
    '16': {
      TITLE: 'Advanced search',
      TEXT: 'Select any auction based on the day it is traded',
    },
  },
  BODY_DEFECTS: {
    TITLE: 'Body defects',
    A1: 'A1 - Small scratch on varnish',
    A2: 'A2 - Scratches on paint',
    A3: 'A3 - Large scratch, possibly on metal',
    A4: 'A4 - Scratch more than 20 cm',
    A5: 'A5 - Covers the entire panel or more',
    B: 'B - Dent with scratch',
    E1: 'E1 - Small Dent',
    E2: 'E2 - Several Small Dents',
    E3: 'E3 - Lots of Small Dents',
    U1: 'U1 - Small Dent',
    U2: 'U2 - Dent',
    U3: 'U3 - Big Dent',
    W1: 'W1 - Repair/Painting (barely detectable)',
    W2: 'W2 - Repair/Painting (visible)',
    W3: 'W3 - Repair/Paint (very noticeable, should be repainted)',
    S1: 'S1 - Subtle Rust',
    S2: 'S2 - Rust',
    C1: 'C1 - Corrosion',
    C2: 'C2 - Noticeable Corrosion',
    P: 'P - The paint is different from the original',
    H: 'H - Paint has deteriorated',
    X: 'X - Element requires replacement',
    XX: 'XX - Replaced Element',
    B1: 'B1 - Small defect on bumpers (about the size of a thumb)',
    B2: 'B2 - Noticeable defect on bumpers',
    Y1: 'Y1 - Small Crack',
    Y2: 'Y2 - Crack',
    Y3: 'Y3 - Great Crack',
    X1: 'X1 - Small Crack on Windshield (approx. 1cm)',
    R: 'R - Repaired Windshield Crack',
    RX: 'RX - Repaired Crack on Windshield (needs replacement)',
    X_CORRECTION: 'X - Crack on the Windshield (needs replacement)',
    G: 'G - Glass stone crack',
  },
  HINT: 'Hint',
  AUCTION_SHEET_ZONES: 'Auction sheet zones',
  ACTIONS: 'Actions',
  PURCHASE_REPORT: 'Purchase report',
  BIDS_LIST: 'Bids list',
  IMPORT_BIDS: {
    TITLE: 'Bids import',
    ALERT:
      'Here you can import your bids into the EcarJP system. The import is done from a file – <a download href="/assets/import-bids-example.xls"><b>download the template</b></a>. <b>User</b> and <b>Group</b> columns are optional. After uploading the file, the system will analyze which of the lots are available for bidding. You will be able to specify the condition, group, and whether notification of the auction results is required. Upon completion of the import, the system will inform you which bids were successfully placed and which ones could not be placed due to <a href="https://ecarjp.com/article/bid-rules" target="_blank"><b>bidding rules</b></a>.',
    UPLOAD_TIP: 'Click or drag file to this area to upload',
    BID: 'Bid',
    GROUP: 'Group',
    STATUS: 'Status',
    LOT_NOT_FOUND: 'not found',
    BIDDABLE: 'Bid is possible',
    MUTATION_ERROR: 'Bid error: {{error}}',
    PLACING: 'Placing bids',
    SUCCESS: 'Success',
    FAIL: 'Failed',
    SUCCESSFULLY_PLACED: 'Successfully placed',
    TABLE_TITLE: {
      PARSED_BIDS_COUNT: 'bids received',
      PLACE_BIDS: 'Place bids',
      PLACE_SELECTED_BIDS: 'Place selected',
      RESET: 'Reset',
    },
    PARSING_ERRORS: {
      UNEXPECTED_ERROR: 'An unexpected error has occurred',
      INVALID_FIELD: 'The "{{field}}" field is not filled in or has an incorrect format',
    },
    ERRORS: {
      'bid-amount-is-not-multiple-5k': 'The bid must be a multiple of 5000',
      'bid-amount-less-then-start-price': 'The bid is less than the starting price',
      'impossible-place-group-bid': 'It is not possible to place a group bid',
      'group-limits-reached': 'Group limits have been reached',
      'no-available-bid-condition': 'There is no suitable bid condition',
      'lot-sanctioned': 'The sanctioned lot',
      'moto-overprice': 'The lot has been withdrawn from the auction',
      'time-over': 'The bidding is over',
      'lots-limit': 'The lot limit has been reached',
      'bids-limit': 'The bidding limit has been reached',
    },
  },
  WATCH_REQUEST: {
    TITLE: 'Photo inspection',
    ALERT_START_LINE:
      'Additional visual inspection of the lot. <br /><br /> The first inspection for your account is free.',
    ALERT_BASE_PRICE: 'Each subsequent inspection costs <b>{{price}} ¥</b> <br /><br />',
    ALERT_END_LINE:
      'Inspection request is available no later than 6 hours before the lot auction. The funds will be debited from your balance. If there is no amount on the balance, the funds will be debited from your deposit. Applications are processed on a first-come, first-served basis. As soon as your request is completed, a link with the results and media files will be sent to your email.',
    ALREADY_CREATED: 'You have already sent a request for a photo inspection for this lot',
    COMMENT: 'Comment (required)',
    SEND: 'Send request',
    IMAGES: 'Images',
    VIDEOS: 'Videos',
  },
  LOT_ADD_NOTE: {
    ADD_TITLE: 'Add note',
    EDIT_TITLE: 'Edit note',
    DELETE_NOTE: 'Delete this note?',
    NOTE: 'Note',
    READY: 'Ready',
  },
};
