import { CalculatorModule } from '@akebono/calculator';
import { AkebonoCoreModule, BirthdayGreetingsModule } from '@akebono/core';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import mn from '@angular/common/locales/mn';
import ru from '@angular/common/locales/ru';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PushModule } from '@rx-angular/template';
import { Angulartics2, Angulartics2Module } from 'angulartics2';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { en_US, mn_MN, NZ_I18N, ru_RU } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageServiceModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { CookieService } from 'ngx-cookie-service';
import { LightboxModule } from 'ngx-lightbox';
import { BiddingRulesService } from 'src/app/services/bidding-rules.service';
import { LayoutService } from 'src/app/services/layout.service';
import { environment } from 'src/environments/environment';

import { en as enLang } from '../locale/en';
import { mn as mnLang } from '../locale/mn';
import { ru as ruLang } from '../locale/ru';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ArticleComponent } from './components/article/article.component';
import { BidCommentsComponent } from './components/bid-comments/bid-comments.component';
import { BidManualModalComponent } from './components/bid-manual-modal/bid-manual-modal.component';
import { BidsFilterComponent } from './components/bids-filter/bids-filter.component';
import { BidsComponent } from './components/bids/bids.component';
import { BoundaryLotsFilterComponent } from './components/boundary-lots-filter/boundary-lots-filter.component';
import { BoundaryLotsComponent } from './components/boundary-lots/boundary-lots.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { LayoutComponent } from './components/layout/layout.component';
import { MainComponent } from './components/main/main.component';
import { NotificationTabComponent } from './components/notification-tab/notification-tab.component';
import { PresetUnsubscribeComponent } from './components/preset-unsubscribe/preset-unsubscribe.component';
import { SelectionsLotsComponent } from './components/selections-lots/selections-lots.component';
import { SubscribeTelegramModalComponent } from './components/subscribe-telegram-modal/subscribe-telegram-modal.component';
import { UserChannelModalComponent } from './components/user-channel-modal/user-channel-modal.component';
import { AuctionsAraiKenkiLotsRoutingModule } from './modules/auctions-arai-kenki-lots/auctions-arai-kenki-lots-routing.module';
import { AuctionsAraiKenkiLotsModule } from './modules/auctions-arai-kenki-lots/auctions-arai-kenki-lots.module';
import { AuctionsAutoStatsModule } from './modules/auctions-auto-stats/auctions-auto-stats.module';
import { AuctionsAutoRoutingModule } from './modules/auctions-auto/auctions-auto-routing.module';
import { AuctionsAutoModule } from './modules/auctions-auto/auctions-auto.module';
import { AuctionsMotoRoutingModule } from './modules/auctions-moto/auctions-moto-routing.module';
import { AuctionsMotoModule } from './modules/auctions-moto/auctions-moto.module';
import { AuctionsOnepriceRoutingModule } from './modules/auctions-oneprice/auctions-oneprice-routing.module';
import { AuctionsOnepriceModule } from './modules/auctions-oneprice/auctions-oneprice.module';
import { AuthRoutingModule } from './modules/auth/auth-routing.module';
import { AuthModule } from './modules/auth/auth.module';
import { ImportBidsModule } from './modules/import-bids/import-bids.module';
import { NewsModule } from './modules/news/news.module';
import { AlertsModule } from './modules/shared-components/alerts-list/alerts.module';
import { BidPriorityPassStatusModule } from './modules/shared-components/bid-priority-pass-status/bid-priority-pass-status.module';
import { ConnectTelegramModule } from './modules/shared-components/connect-telegram/connect-telegram.module';
import { CountryConfigModule } from './modules/shared-components/country-config/country-config.module';
import { CreateUserRequestModalModule } from './modules/shared-components/create-user-request-modal/create-user-request-modal.module';
import { DriverModule } from './modules/shared-components/driver/driver.module';
import { LanguageSelectorModule } from './modules/shared-components/language-selector/language-selector.module';
import { LotBidAmountModule } from './modules/shared-components/lot-bid-amount/lot-bid-amount.module';
import { LotMainInfoModule } from './modules/shared-components/lot-main-info/lot-main-info.module';
import { LotMiniGalleryModule } from './modules/shared-components/lot-mini-gallery/lot-mini-gallery.module';
import { LotsUniversalTableModule } from './modules/shared-components/lots-universal-table/lots-universal-table.module';
import { MainAlertsModule } from './modules/shared-components/main-alerts/main-alerts.module';
import { SettingsModalModule } from './modules/shared-components/settings-modal/settings-modal.module';
import { ShareButtonsModule } from './modules/shared-components/share-buttons/share-buttons.module';
import { SharedPagesRoutingModule } from './modules/shared-components/shared-pages/shared-pages-routing.module';
import { SharedPagesModule } from './modules/shared-components/shared-pages/shared-pages.module';
import { UserPurchaseStatisticsModule } from './modules/shared-components/user-purchase-statistics/user-purchase-statistics.module';
import { AuctionService } from './services/auction.service';
import { BidConditionService } from './services/bid-condition.service';
import { LimitsService } from './services/limits.service';
import { LotBoundaryInfoService } from './services/lot-boundary-info.service';
import { S3FileUploaderService } from './services/s3-file-uploader.service';
import { SpecialAlertsService } from './services/special-alerts.service';
import { UploadsService } from './services/uploads.service';
import { NavUserProfileModule } from './modules/shared-components/nav-user-profile/nav-user-profile.module';
import { CollapsedTextComponent } from './components/collapsed-text/collapsed-text.component';
import { CollectionRenameModalComponent } from './components/collection-rename-modal/collection-rename-modal.component';

registerLocaleData(en);
registerLocaleData(ru);
registerLocaleData(mn);

@Injectable()
class AkebonoErrorHandler extends ErrorHandler {
  constructor(private angulartics2: Angulartics2) {
    super();
  }

  handleError(error: Error) {
    console.log(error);
    const userId = localStorage?.getItem(
      `CognitoIdentityServiceProvider.${environment?.cognitoPool?.ClientId}.LastAuthUser`,
    );
    if (userId) {
      this.angulartics2.setUsername.next(userId);
    }
    this.angulartics2.eventTrack.next({
      action: 'Error',
      properties: {
        message: error.message,
        name: error.name,
        stack: error.stack,
        string: String(error),
      },
    });
  }
}

function initializeApplication(translate: TranslateService, angulartics2: Angulartics2) {
  return () => {
    const userId = localStorage.getItem(
      `CognitoIdentityServiceProvider.${environment.cognitoPool.ClientId}.LastAuthUser`,
    );

    if (userId) {
      angulartics2.setUsername.next(userId);
    }

    translate.setTranslation('ru', ruLang, true);
    translate.setTranslation('en', enLang, true);
    translate.setTranslation('mn', mnLang, true);
  };
}
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    BidsComponent,
    LayoutComponent,
    ForbiddenComponent,
    BidsFilterComponent,
    BidCommentsComponent,
    BidManualModalComponent,
    SelectionsLotsComponent,
    BoundaryLotsComponent,
    BoundaryLotsFilterComponent,
    ArticleComponent,
    UserChannelModalComponent,
    CollectionRenameModalComponent,
    PresetUnsubscribeComponent,
    NotificationTabComponent,
    SubscribeTelegramModalComponent,
    CollapsedTextComponent,
  ],
  imports: [
    BrowserModule,
    AuthModule,
    FormsModule,
    PushModule,
    NewsModule,
    AlertsModule,
    HttpLinkModule,
    NzToolTipModule,
    HttpClientModule,
    AppRoutingModule,
    AuthRoutingModule,
    AuctionsMotoModule,
    SettingsModalModule,
    SharedPagesModule,
    AuctionsAutoModule,
    ImportBidsModule,
    AuctionsAutoStatsModule,
    LotMiniGalleryModule,
    AuctionsAraiKenkiLotsModule,
    LotsUniversalTableModule,
    BrowserAnimationsModule,
    AuctionsOnepriceModule,
    SharedPagesRoutingModule,
    AuctionsAutoRoutingModule,
    AuctionsMotoRoutingModule,
    CreateUserRequestModalModule,
    AuctionsOnepriceRoutingModule,
    AuctionsAraiKenkiLotsRoutingModule,
    AkebonoCoreModule.forRoot({
      domain: environment.domain,
      allowedLanguages: ['ru', 'en', 'mn'],
      publicFallbackPageURI: '/sign-in',
      permissionsFallbackPageURI: '/403',
      apolloClientsConfig: environment.graphql,
      cognito: environment.cognitoPool,
      skipCountryCheck: true,
      skipOperationNames: ['checkAgentDebts'],
    }),
    TranslateModule.forRoot(),
    Angulartics2Module.forRoot(),
    NzTagModule,
    NzGridModule,
    NzCardModule,
    NzSpinModule,
    NzIconModule,
    NzFormModule,
    NzTabsModule,
    NzMenuModule,
    NzTableModule,
    NzSwitchModule,
    NzAlertModule,
    NzInputModule,
    NzModalModule,
    NzRadioModule,
    NzDrawerModule,
    NzLayoutModule,
    LightboxModule,
    NzResultModule,
    NzPopoverModule,
    NzButtonModule,
    NzAvatarModule,
    NzSelectModule,
    NzDividerModule,
    NzCommentModule,
    CalculatorModule,
    NzCollapseModule,
    NzCheckboxModule,
    NzCalendarModule,
    NzSkeletonModule,
    NzCarouselModule,
    NzPageHeaderModule,
    NzBreadCrumbModule,
    NzTypographyModule,
    NzDatePickerModule,
    NzInputNumberModule,
    NzDescriptionsModule,
    NzNotificationModule,
    NzMessageServiceModule,
    MainAlertsModule,
    NzPopconfirmModule,
    ShareButtonsModule,
    NzUploadModule,
    LotBidAmountModule,
    BidPriorityPassStatusModule,
    BirthdayGreetingsModule,
    LotMainInfoModule,
    NzSpaceModule,
    NzDatePickerModule,
    LanguageSelectorModule,
    NavUserProfileModule,
    CountryConfigModule,
    ConnectTelegramModule,
    NzListModule,
    UserPurchaseStatisticsModule,
    DriverModule,
  ],
  providers: [
    CookieService,
    LayoutService,
    UploadsService,
    TranslateService,
    LimitsService,
    BidConditionService,
    AuctionService,
    SpecialAlertsService,
    BiddingRulesService,
    S3FileUploaderService,
    LotBoundaryInfoService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslateService, Angulartics2],
      useFactory: initializeApplication,
    },
    {
      provide: NZ_I18N,
      useFactory: (localId: TranslateService) => {
        switch (localId.currentLang) {
          case 'en':
            return en_US;
          case 'ru':
            return ru_RU;
          case 'mn':
            return mn_MN;
          default:
            return ru_RU;
        }
      },
      deps: [TranslateService],
    },
    {
      provide: ErrorHandler,
      useClass: AkebonoErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
