<nz-image-group>
  <swiper #swiper [config]="configSwiper" (activeIndexChange)="setActiveSlide($event.activeIndex)">
    <ng-template *ngFor="let mediaItem of media" swiperSlide>
      <app-lot-carousel-main-slider-item
        [mediaItem]="mediaItem"
        (loadingError)="mediaItemLoadingError(mediaItem)"
      ></app-lot-carousel-main-slider-item>
    </ng-template>
  </swiper>
</nz-image-group>

<ng-container *ngIf="isUsingSavedMedia">
  <app-lot-carousel-main-slider-move-actions
    *akbCheck="['world.akebono.auto.auctioneer']"
    [lotId]="lotId"
    [lotType]="lotType"
    [lotSource]="lotSource"
    [activeSlideIndex]="activeSlideIndex"
    [media]="media"
    (mediaSaved)="mediaSaved.emit()"
    (mediaOrderChanged)="orderChanged($event)"
  ></app-lot-carousel-main-slider-move-actions>
  <app-lot-carousel-main-slider-auction-tip-actions
    [auctionName]="auctionName"
  ></app-lot-carousel-main-slider-auction-tip-actions>
</ng-container>
