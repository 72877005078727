import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-auctions-tip-modal',
  templateUrl: './auctions-tip-modal.component.html',
  styleUrls: ['./auctions-tip-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuctionsTipModalComponent implements OnInit, AfterViewInit {
  @Input() auctionName: string;
  elemImgWidth = 774;
  elemImgHeight = 878;
  currentLang = 'ru';
  activeTabIndex = 0;

  constructor(private translateService: TranslateService) {}

  @HostListener('window:resize')
  onResize(): void {
    this.initTabTipActions();
  }

  ngOnInit(): void {
    this.currentLang = this.defaultLanguage;
    this.setDefaultTabIndex();
  }

  private get defaultLanguage(): string {
    return this.translateService.currentLang === 'ru' ? 'ru' : 'en';
  }

  setDefaultTabIndex(): void {
    const auctionName = this.auctionName.split(' ')[0].toUpperCase();
    const tabIndex = {
      USS: 0,
      TAA: 1,
      CAA: 2,
      JU: 3,
      LAA: 4,
      ARAI: 5,
      HERO: 6,
      IAA: 7,
      BAYAUC: 8,
      MIRIVE: 9,
    }[auctionName];

    this.activeTabIndex = tabIndex || 0;
  }

  ngAfterViewInit(): void {
    this.initTabTipActions();
  }

  changeTab(): void {
    this.initTabTipActions();
  }

  initTabTipActions(): void {
    const blocks = document.querySelectorAll('.shame_blocks, .shame_blocks_clone');
    this.setAttrs(blocks);
    this.resetAttrs();
    this.initHoverListener();
  }

  hoverEf(parent: HTMLElement, target: HTMLElement): void {
    parent.addEventListener('mouseenter', () => {
      target.classList.add('hover');
      parent.classList.add('hover');
    });
    parent.addEventListener('mouseleave', () => {
      target.classList.remove('hover');
      parent.classList.remove('hover');
    });
  }

  initHoverListener(): void {
    document.querySelectorAll('.auc_list_shame').forEach((item) => {
      item.querySelectorAll('.shame_blocks').forEach((shameBlock: HTMLElement) => {
        const elemClone = shameBlock.cloneNode(false) as HTMLElement;
        elemClone.classList.add('shame_blocks_clone');
        elemClone.classList.remove('shame_blocks');
        elemClone.removeAttribute('id');
        elemClone.innerHTML = '';
        item.appendChild(elemClone);
        this.hoverEf(shameBlock, elemClone);
      });
    });
  }

  setAttrs(elements: NodeListOf<Element>): void {
    elements.forEach((item: HTMLElement) => {
      item.removeAttribute('style');
      const baseImg = item.parentElement.querySelector<HTMLElement>('.shame_img');
      baseImg.setAttribute('data-base-width', String(baseImg.offsetWidth));
      baseImg.setAttribute('data-base-height', String(baseImg.offsetHeight));
      const targetWidth = 100 / (this.elemImgWidth / parseFloat(getComputedStyle(item).width));
      const targetHeight = 100 / (this.elemImgHeight / parseFloat(getComputedStyle(item).height));
      const targetTop = 100 / (this.elemImgHeight / parseFloat(getComputedStyle(item).top));
      const targetLeft = 100 / (this.elemImgWidth / parseFloat(getComputedStyle(item).left));
      item.setAttribute('data-top', String(targetTop));
      item.setAttribute('data-left', String(targetLeft));
      item.setAttribute('data-width', String(targetWidth));
      item.setAttribute('data-height', String(targetHeight));
      item.setAttribute('data-base-top', String(item.offsetTop));
      item.setAttribute('data-base-left', String(item.offsetLeft));
      item.setAttribute('data-base-width', getComputedStyle(item).width);
      item.setAttribute('data-base-height', getComputedStyle(item).height);
      item.setAttribute('data-parent-width', String(this.elemImgWidth));
    });
  }

  resetAttrs(): void {
    document.querySelectorAll('.auc_list_shame').forEach((item) => {
      const elemImg = item.querySelector<HTMLElement>('.shame_img');
      const elemWidth = elemImg.offsetWidth;
      if (this.elemImgWidth !== elemWidth) {
        elemImg.setAttribute('data-base-width', elemWidth.toString());
        const coof = 100 * (elemWidth / this.elemImgWidth) * 0.01;
        item
          .querySelectorAll('.shame_blocks, .shame_blocks_clone')
          .forEach((element: HTMLElement) => {
            const width = parseFloat(element.getAttribute('data-base-width'));
            const height = parseFloat(element.getAttribute('data-base-height'));
            const top = parseFloat(element.getAttribute('data-base-top'));
            const left = parseFloat(element.getAttribute('data-base-left'));
            element.style.width = width * coof + 'px';
            element.setAttribute('data-base-width', String(width * coof));
            element.style.height = height * coof + 'px';
            element.setAttribute('data-base-height', String(height * coof));
            element.style.top = top * coof + 'px';
            element.setAttribute('data-base-top', String(top * coof));
            element.style.left = left * coof + 'px';
            element.setAttribute('data-base-left', String(left * coof));
          });
        elemImg.setAttribute('data-base-width', String(elemWidth));
      }
    });
  }
}
