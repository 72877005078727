import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import { MediaItem } from '../../types/media-item.type';

@Component({
  selector: 'app-lot-carousel-thumbs-item',
  templateUrl: './lot-carousel-thumbs-item.component.html',
  styleUrls: ['./lot-carousel-thumbs-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCarouselThumbsItemComponent {
  @Input() mediaItem: MediaItem;
  @Input() index: number;
  @Output() thumbSelected = new EventEmitter<number>();

  @HostListener('click')
  selectThumb(): void {
    this.thumbSelected.emit(this.index + 1);
  }

  get mediaType(): MediaItem['mediaType'] {
    return this.mediaItem.mediaType;
  }

  get source(): string {
    return this.mediaItem.source;
  }
}
