import { MutationHandlingServiceV2 } from '@akebono/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  LotSource,
  LotTypeEnum,
  LotUpdateGQL,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';
import { environment } from 'src/environments/environment';

import { MediaItem } from '../../types/media-item.type';
import { MediaOrderChanged } from '../../types/media-order-changed.type';

@Component({
  selector: 'app-lot-carousel-main-slider-move-actions',
  templateUrl: './lot-carousel-main-slider-move-actions.component.html',
  styleUrls: ['./lot-carousel-main-slider-move-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCarouselMainSliderMoveActionsComponent {
  @Input() lotId: string;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() activeSlideIndex = 0;
  @Input() media: MediaItem[] = [];
  @Output() mediaSaved = new EventEmitter<void>();
  @Output() mediaOrderChanged = new EventEmitter<MediaOrderChanged>();

  isMovingPhotos = false;

  saving$ = of(false);

  constructor(private mhs: MutationHandlingServiceV2, private lotUpdateGQL: LotUpdateGQL) {}

  saveMedia(media?: MediaItem[]): void {
    this.saving$ = this.mhs
      .mutate(
        this.lotUpdateGQL,
        {
          input: {
            lotId: this.lotId,
            lotType: this.lotType,
            lotSource: this.lotSource,
            photos: (media || this.media)
              .filter((media) => media.type === 'regular')
              .map((media) => media.source)
              .map((url) =>
                url.replace(`${environment.domain}/uss/`, `${environment.domain}/pp/uss/`),
              ),
          },
        },
        {
          successTranslationKey: 'MUTATION_SUCCEEDED',
          failureTranslationKey: 'MUTATION_FAILED',
        },
      )
      .loading.pipe(
        finalize(() => {
          this.mediaSaved.emit();
          this.isMovingPhotos = false;
        }),
      );
  }

  get normalizedActiveSlideIndex(): number {
    return this.activeSlideIndex - 1;
  }

  get activeSlideUrl(): string {
    return this.media[this.normalizedActiveSlideIndex]?.source;
  }

  get isCanMoveRight(): boolean {
    return (
      this.media.length > 0 &&
      this.currentMediaIsRegular &&
      this.normalizedActiveSlideIndex < this.media.length &&
      this.media[this.normalizedActiveSlideIndex + 1]?.type === 'regular'
    );
  }

  get isCanMoveLeft(): boolean {
    return (
      this.media.length > 0 && this.currentMediaIsRegular && this.normalizedActiveSlideIndex > 0
    );
  }

  moveRight(): void {
    return this.moveMedia(this.normalizedActiveSlideIndex + 1);
  }

  moveLeft(): void {
    return this.moveMedia(this.normalizedActiveSlideIndex - 1);
  }

  deleteMedia(): void {
    this.saveMedia(this.media.filter((_, index) => index !== this.normalizedActiveSlideIndex));
  }

  get currentMediaIsRegular(): boolean {
    return this.media[this.normalizedActiveSlideIndex]?.type === 'regular';
  }

  private moveMedia(toIndex: number): void {
    if (!this.isMovingPhotos) {
      this.isMovingPhotos = true;
    }

    const media = [...this.media];
    const currentMedia = media[this.normalizedActiveSlideIndex];
    media[this.normalizedActiveSlideIndex] = media[toIndex];
    media[toIndex] = currentMedia;

    this.mediaOrderChanged.emit({
      activeSlideIndex: toIndex + 1,
      media,
    });
  }
}
