<!-- <button nz-button nzSize="large" [nzBlock]="fullSize" (click)="openLotAddListButtonComponent()">
  {{ 'SHARED.SELECT_LIST' | translate }}
</button> -->

<div>
  <button
    nz-button
    nzSize="large"
    nz-dropdown
    [nzDropdownMenu]="menu"
    [nzPlacement]="'bottomCenter'"
    [nzLoading]="markCreating$ | async"
  >
    {{ 'SHARED.ADD_TO_SELECTION' | translate }}
  </button>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <nz-spin [nzSpinning]="listCreating$ | async">
        <li nz-menu-item (click)="userLotMarksListCreate()">
          {{ 'SHARED.ADD_NEW_LISTS' | translate }}
        </li>
      </nz-spin>
      <nz-divider></nz-divider>
      <li
        nz-menu-item
        *ngFor="let item of (queryRef.data | async)?.currentUser?.lotMarksLists"
        (click)="userLotMarkCreate(item.id)"
      >
        {{ item?.title }}
      </li>
    </ul>
  </nz-dropdown-menu>
</div>
