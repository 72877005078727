import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-lot-carousel-main-slider-video-modal',
  templateUrl: './lot-carousel-main-slider-video-modal.component.html',
  styleUrls: ['./lot-carousel-main-slider-video-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCarouselMainSliderVideoModalComponent {
  @Input() source: string;

  constructor(private readonly modal: NzModalRef) {}

  close(): void {
    this.modal.destroy();
  }
}
