import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { AuctionsTipModalComponent } from '../auctions-tip-modal/auctions-tip-modal.component';

@Component({
  selector: 'app-lot-carousel-main-slider-auction-tip-actions',
  templateUrl: './lot-carousel-main-slider-auction-tip-actions.component.html',
  styleUrls: ['./lot-carousel-main-slider-auction-tip-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCarouselMainSliderAuctionTipActionsComponent {
  @Input() auctionName: string;

  constructor(
    private readonly translate: TranslateService,
    private readonly modalService: NzModalService,
  ) {}

  openAuctionListTipModal(): void {
    this.modalService.create({
      nzTitle: this.translate.instant('LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TITLE'),
      nzContent: AuctionsTipModalComponent,
      nzWidth: '900px',
      nzFooter: null,
      nzStyle: {
        top: '10px',
      },
      nzComponentParams: {
        auctionName: this.auctionName,
      },
      nzClassName: 'auction-tip-modal',
    });
  }
}
