<form nz-form [formGroup]="form">
  <nz-form-item>
    <nz-form-label [nzRequired]="true" [nzSpan]="8">
      {{ 'CHANNEL.COLLECTION_TITLE' | translate }}
    </nz-form-label>
    <nz-form-control>
      <input nz-input formControlName="collectionTitle" />
    </nz-form-control>
  </nz-form-item>
</form>

<footer *nzModalFooter>
  <button (click)="closeModal()" nz-button nzType="default">
    {{ 'CANCEL' | translate }}
  </button>
  <button
    nz-button
    nzType="primary"
    (click)="collectedNamelUpdate()"
    [nzLoading]="loading$ | async"
  >
    <span>{{ 'UPDATE' | translate }}</span>
  </button>
</footer>
