import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { videoExtensionsSet } from 'src/app/constants/video-extensions';
import SwiperCore, { Keyboard, Navigation, Pagination } from 'swiper';

import { AutoLotView } from '../../../const';
import {
  LotSource,
  LotTypeEnum,
  UserLotQuery,
} from '../../graphql/service/graphql-auto-main-service';
import { MediaItem } from './types/media-item.type';

SwiperCore.use([Pagination, Navigation, Keyboard]);

@Component({
  selector: 'app-lot-carousel',
  templateUrl: './lot-carousel.component.html',
  styleUrls: ['./lot-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCarouselComponent implements OnChanges {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() activeSlide: number = 0;
  @Input() isUsingSavedImages: boolean;
  @Input() currentUser?: UserLotQuery['currentUser'];
  @Output() activeSlideChange = new EventEmitter<number>();
  @Output() mediaChanged = new EventEmitter<void>();

  media: MediaItem[] = [];

  activeSlideChanged(index: number): void {
    this.activeSlide = index;
    this.activeSlideChange.emit(index);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.lot) {
      return;
    }

    const clearPhotos =
      this.currentUser?.isAuctioneer ||
      this.currentUser?.isTranslator ||
      this.currentUser?.hasClearPhotos;

    this.media = [
      ...this.mediaUrlsToMediaItems(this.lot.images || [], 'regular', clearPhotos),
      ...this.mediaUrlsToMediaItems(this.lot.inspectionMedia || [], 'inspection', clearPhotos),
    ];
  }

  private mediaUrlsToMediaItems(
    mediaUrls: string[],
    type: MediaItem['type'],
    clearPhotos: boolean,
  ): MediaItem[] {
    return mediaUrls.map((url) => {
      const mediaType = this.getMediaType(url);
      switch (mediaType) {
        case 'image':
          return <MediaItem>{
            source: clearPhotos ? url.replace('/pp', '').replace('/qp', '') : url,
            mediaType,
            type,
          };
        case 'video':
          return <MediaItem>{
            source: url,
            mediaType,
            type,
          };
      }
    });
  }

  private getMediaType(url: string): MediaItem['mediaType'] {
    const extension = url.split('.').pop().toLowerCase();
    return videoExtensionsSet.has(extension) ? 'video' : 'image';
  }
}
