<nz-spin [nzSpinning]="saving$ | async">
  <nz-button-group>
    <button
      nz-button
      nz-tooltip
      nzType="primary"
      [disabled]="!isCanMoveLeft"
      [nzTooltipTitle]="'LOT_CAROUSEL.MOVE_LEFT' | translate"
      (click)="moveLeft()"
    >
      <i nz-icon nzType="left" nzTheme="outline"></i>
    </button>
    <button
      nz-button
      nz-tooltip
      nzType="primary"
      [disabled]="!isCanMoveRight"
      [nzTooltipTitle]="'LOT_CAROUSEL.MOVE_RIGHT' | translate"
      (click)="moveRight()"
    >
      <i nz-icon nzType="right" nzTheme="outline"></i>
    </button>
    <button
      nz-button
      nz-tooltip
      nzType="primary"
      [disabled]="!isMovingPhotos"
      [nzTooltipTitle]="'LOT_CAROUSEL.SAVE_CHANGES' | translate"
      (click)="saveMedia()"
    >
      <i nz-icon nzType="check" nzTheme="outline"></i>
    </button>
    <button
      nz-button
      nzDanger
      nz-tooltip
      nz-popconfirm
      nzType="primary"
      nzPopconfirmPlacement="bottom"
      [disabled]="!currentMediaIsRegular"
      [nzTooltipTitle]="'LOT_CAROUSEL.DELETE_PHOTO_TOOLTIP' | translate"
      [nzPopconfirmTitle]="'LOT_CAROUSEL.DELETE_PHOTO' | translate"
      (nzOnConfirm)="deleteMedia()"
    >
      <i nz-icon nzType="delete" nzTheme="outline"></i>
    </button>
    <a
      nz-button
      [href]="activeSlideUrl"
      [disabled]="!activeSlideUrl"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i nz-icon nzType="download" nzTheme="outline"></i>
    </a>
  </nz-button-group>
</nz-spin>
