import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { MediaItemIndexed } from '../../types/media-item-indexed.type';
import { MediaItem } from '../../types/media-item.type';

@Component({
  selector: 'app-lot-carousel-thumbs',
  templateUrl: './lot-carousel-thumbs.component.html',
  styleUrls: ['./lot-carousel-thumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCarouselThumbsComponent {
  @Input()
  set media(media: MediaItem[]) {
    const indexedMedia = (media || []).map((item, index) => ({
      ...item,
      index,
    }));

    this.regularMedia = indexedMedia.filter((item) => item.type === 'regular');
    this.inspectionMedia = indexedMedia.filter((item) => item.type === 'inspection');
  }
  @Output() activeSlideIndexChanged = new EventEmitter<number>();

  regularMedia: MediaItemIndexed[] = [];
  inspectionMedia: MediaItemIndexed[] = [];
}
